import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from 'moment-timezone';
import isMobilePhone from 'validator/lib/isMobilePhone';
import ReactGA from "react-ga4";

import { Col, Row, Form, Button, Container, InputGroup, Modal } from 'react-bootstrap';
import { PhoneIcon, MailIcon } from "@heroicons/react/solid";
import Swal from 'sweetalert2';

import { useParams } from "react-router-dom";

import { useAuth } from "pages/access/useAuth"; 

// JKG Ficus Specific Starts Here
import EduLogo from "assets/img/logos/PointU_Stacked-Small.png";

// JKG - Make dynamic, driven from organizationID passed in by URL

import PointLogoMaster from "assets/img/logos/PointU_Master.png"; 
import FicusLogoSmall from "assets/img/logos/FicusEducation_Logo_Secondary_CROPPED_CMYK.png"; 

const OrganizationName = "5000 Futures"


const RequiredLabel = ({ label }) => (
  <Form.Label>
    {label}
    <span className="text-danger"> * </span>
  </Form.Label>
);


function FicusInquiryForm(props) {	
	let {id} = useParams()
	const { 
		secToken,
		endpoint,
		proxy
	} = useAuth(); 	
	
	
	const [location, setLocation] = useState(null);
	const [companyid, setCompanyid] = useState(null);
	const [showTCModal, setShowTCModal] = useState(false);
	const [submitted, setSubmitted] = useState(false)

	const firstName = useRef("");
	const lastName = useRef("");
	const email = useRef("");
	const phone = useRef("");
	const optin = useRef("");
	const locationid = id;

  async function ShowAlert(theurl) { 
  	Swal.hideLoading();
		await Swal.fire({
			title: '<h1 style="color:#013d87; font-size:2rem"><b>Thank You!</b></h1>'+
				'<h3 style="margin-bottom:0px; color:#013d87"><b>Step 2: Check Your Email!</b></h3>',
			html: '<div style="margin-bottom:15px">'+
			 `<img src=${EduLogo} style="width:150px" />`+
			 '<p style="font-size:1.2rem; font-weight:500; line-height:inherit; margin-top:15px; width: 90%; margin-left: 5%; color: #013d87;">You will receive an email in the next few minutes to access to your Online Application</p>'+
			 '<p style="font-size:14px; line-height:inherit; text-align:left; margin-top:15px; width: 90%; margin-left: 5%;">While you wait for your application email, you can click below to learn more about the program and enrollment process.</p>'+
			 '<a class="animate-up-2 btn btn-tertiary btn-sm " style="margin: 3 auto; margin-bottom:10px; background-color: rgb(1, 61, 135); border-color: rgb(59, 89, 124);" href="'+theurl+'">The Point University Enrollment Process</a>'+
			 '</div><div>'+
			 '<p style="font-size:12px; text-align:left; width: 90%; margin-left: 5%;"><b>Not quite ready to apply?</b>  Click here to learn more from Ficus Education about the free college education program and the application process. '+
			 ' <a style="text-decoration:underline; color:royalblue" href="https://ficuseducation.com/student-journey">Get more Information...</a></p>'+
			 '</div>',
			showCancelButton: false, 
			showConfirmButton: false 		
  	}).then((result) => {
  		window.location.href = 'https://ficuseducation.com/student-journey'  		
  	});
	}

	const submitInquiry = (e) => {		 	
  	e.preventDefault();
  	const submitButton = document.querySelector("button[type='submit']");
		
    if (submitted) {
        return;
    }
    setSubmitted(true);
		submitButton.innerText = 'Processing Request...';
		submitButton.disabled = true;
    
		
		/* WARNING - HACK */
		const placeholderid = 99000000 + Math.floor(Math.random() * (999999 - 100000 + 1) + 100000)
		
		/* END HACK */
		var personinfo =
			{
		    "firstname":firstName.current.value,
		    "lastname":lastName.current.value,
		    "email": email.current.value,
				"phone": phone.current.value,
				"locationid": locationid,
				"companyid": companyid,
				"hubspotid": placeholderid,
				"status": 'New Inquiry',				
				"isstudent": 'Yes'				
			}

  	let formData = new FormData(); 
  	Object.keys(personinfo).forEach(key => formData.append(key, personinfo[key]));  	
  	let pinfo = new URLSearchParams(formData).toString()
  	
		axios.post(endpoint+'/student/default.asp', pinfo, {
				params: {'sectoken': secToken }	
			})
			.then(response => {
					/* Get payloads ready for Point.  RFI for inquiry, appurl for redirect to Point application */
					const newficusid = response.data;
					
					const rfipayload = {
						'PersonID':newficusid,
						'LocationID':locationid,
						'CompanyID':companyid,
						'FirstName':firstName.current.value,
						'LastName':lastName.current.value,
						'Email':email.current.value,
						'Mobile':phone.current.value,
						'Created':moment().format("MM/DD/YYYY"),
						'PostalCode':location.PostalCode
					}			

					const appurl = {
						'ficusid':newficusid,
						'dealid':companyid,
						'storeid':locationid,
						'companyid':companyid,
						'applicant-first':firstName.current.value,
						'applicant-last':lastName.current.value,
						'email_address':email.current.value,
						'mobile_phone':phone.current.value
					}			
			  	let urldata = new FormData(); 
			  	Object.keys(appurl).forEach(key => urldata.append(key, urldata[key]));			  	
			  	let urlparam = new URLSearchParams(appurl).toString();		

					/* Next send the new student record to Point.  */
{/*
					let rfidata = 'post_body='+JSON.stringify(rfipayload);
					if (proxy.indexOf('portal.ficuseducation.com') !== -1 
						|| companyid === '61950' || companyid === '51250' || companyid === '51177') // DO NOT SEND TO POINT IF NOT IN PRODUCTION DOMAIN AT THIS TIME OR IS A TEST COMPANY 			
						axios.post(proxy, rfidata, {
							params: {  
								'sectoken': secToken, 
								'url':"https://ficus.azurewebsites.net/api/ficus_student?code=f5QFSzjRV6minYW2AnmCThimcjqqeXFOntB-3fE9ONMjAzFuHqiF8Q==", 
								'status_only_ind':'No', 
								'Content_Type':'application/json',
							}	
						})
						.then(response => {
								console.log("Student record sent to edu provider (Point)");		
						})
						.catch((err) => {
		        	console.error(err);
		  			})
					} else {
						  console.log('TEST ONLY - INQUIRY RECORD  NOT SENT TO POINT.  Personid:'+newficusid+'  Proxy:'+proxy)
					}	
					  			
*/}
					/* Add the student to Ficus HubSpot */					
					var newContact = 
				  { "properties": {
					    "firstname":firstName.current.value,
					    "lastname":lastName.current.value,
					    "email": email.current.value,
							"phone": phone.current.value,
			    		"lifecyclestage": "other",
			    		"ficus_role": "Student"
						}
					};
					
					let formdata = new URLSearchParams({'post_body': JSON.stringify(newContact)}).toString();

					axios.post(proxy, formdata, {
							params: { 
								'sectoken': secToken, 
								'url':"https://api.hubapi.com/crm/v3/objects/contacts", 
								'status_only_ind':'No', 
								'Content_Type':'application/json',
								'authorization_header': 'Bearer pat-na1-5e11e810-64bd-4933-867f-846d6c66e09b' }	
						})  
					  .then(response => {
							const newid = response.data.id;	 
							
							/* Update the record in FicusDB with newly created hubspot id (replace placeholder) */
							axios.post(endpoint+'/student/default.asp', pinfo, {
									params: {
										'action': 'sethsid',
										'sectoken': secToken,
										'personid': newficusid,
										'hubspotid': newid
										}	
								})
								.then(response => {
										console.log("Updated Persons with new HSContactID");
								})
							/* First create the association in HubSpot */
							const assn = {
								inputs:[
									{from:{id:newid},
									to:{id:location.HSCompanyID},
									type: "contact_to_company"}			
								]}							
							
							let assndata = new URLSearchParams({'post_body': JSON.stringify(assn)}).toString();

							axios.post(proxy, assndata, {
								params: { 
									'sectoken': secToken, 
									'url':"https://api.hubapi.com/crm/v3/associations/Contacts/Companies/batch/create", 
									'status_only_ind':'No', 
									'Content_Type':'application/json',
									'authorization_header': 'Bearer pat-na1-5e11e810-64bd-4933-867f-846d6c66e09b' }	
							})
							.then(response => {
									console.log("Assn Created for new Inquiry");		
//	ShowAlert('https://point.formstack.com/forms/online_application_?'+urlparam);
										ShowAlert('https://point.edu/admissions/online/elevate-programs/');				
							})
							.catch((err) => {
			        	console.error(err);
//	ShowAlert('https://point.formstack.com/forms/online_application_?'+urlparam);
								ShowAlert('https://point.edu/admissions/online/elevate-programs/');				
//							ShowAlert('https://point.elluciancrmrecruit.com/Apply/Account/Login?ReturnUrl=%2fApply%2fApplication%2fApply%3ftype%3delcn_pudceapplication&type=elcn_pudceapplication');				

			  			})
			  		})
						.catch((err) => {
								console.error(err);
//	ShowAlert('https://point.formstack.com/forms/online_application_?'+urlparam);		        	
								ShowAlert('https://point.edu/admissions/online/elevate-programs/');				
//							ShowAlert('https://point.elluciancrmrecruit.com/Apply/Account/Login?ReturnUrl=%2fApply%2fApplication%2fApply%3ftype%3delcn_pudceapplication&type=elcn_pudceapplication');				
							})
				
			})

	};  		
  
  function validatePhone(e) {
  	const field = phone.current;
  	if (field.value && !isMobilePhone(field.value)) {
			field.setCustomValidity("Please enter a valid phone number");
		} else {
			field.setCustomValidity("");		
		}
		field.reportValidity()

  }
  
	useEffect(() => {
	  	axios.get(endpoint+"/location/default.asp", {
			  		params: { 'id': id }	
		  })  
		  .then(response => {
		  	const theResult = response.data;
		  	console.log(theResult);
		  	setLocation(response.data.length ? theResult[0] : null );
		  	setCompanyid(theResult[0].CompanyID );
		  })
	  	.catch(error => console.log(error));
	},[id, endpoint]);
		  	
	
	if (location) {		
		return (
			<div>	

				<Row show={'true'} className="mx-n3" style={{backgroundColor: "rgb(237 236 236)" }}>
	      	<div className="text-center text-md-center mt-md-0 px-0">
{/*
	          <img src={HeroImage2} alt={OrganizationName}  />
*/}
	          <img src={FicusLogoSmall} className="my-1" alt={OrganizationName} style={{ width: "75%" }} />
	          <div style={{
	          	    paddingLeft: "5%",
    							paddingRight: "5%",
    							paddingTop: "0.9rem",
    							paddingBottom: "0.5rem",
    							backgroundColor: "gray" }}>
	          	<img src={PointLogoMaster} alt={OrganizationName}  />
							<a href="https://forms.office.com/pages/responsepage.aspx?id=4d62Ip6TLEiGxU7rINYN3qa_2rezLuZOrIutDwpcH1RUNFFLSlZPUk1ZUTJSQVVYSFNUSktRRlg4Mi4u"
							 style={{color: "white", fontSize:"0.8rem"}} 
							 onClick={()=> ReactGA.send({ hitType: "click", page: "/inquiry/"+id, title: 'Webinar: '+location.DisplayLocationName }) }
							 		rel="noopener noreferrer" target='_blank'>
							Learn more! Click to register for a free webinar.</a>	          
{/*
      <ReactGA.OutboundLink
        eventLabel="Point Webinar"
        to="https://forms.office.com/pages/responsepage.aspx?id=4d62Ip6TLEiGxU7rINYN3qa_2rezLuZOrIutDwpcH1RUNFFLSlZPUk1ZUTJSQVVYSFNUSktRRlg4Mi4u"
        target="_blank">
        Learn more! Click to register for a free webinar.
      </ReactGA.OutboundLink>
*/}
						</div >
	        </div>
{/*
				<Row className="justify-content-center" style={{backgroundImage: `url(${BackgroundImage})`, backgroundSize: "cover", backgroundColor: "#a3a2a2", marginLeft: "-15px" }} >
					<Col xs={6} md={4} xl={2} >        		
		          <a href="https://ficuseducation.com/student-journey" target="_blank" className="ms-n1">
		          	<img src={OrganizationLogo} alt={OrganizationName} title={OrganizationName} style={{maxWidth: "190px"}}/> 
		          </a>
      		</Col>
					<Col xs={6} md={4} xl={2} className="text-center pt-2 " style={{marginTop: "8px"}}>        		
		          <img src={EduLogo} alt="Point University" title="Point University"  style={{maxWidth: "125px"}}/> 			          
					</Col>
				</Row>
*/}
				</Row>
				<Row show={'true'} className="mx-n3 py-2">
					<h1 className="text-center pt-2 mb-0" style={{color:'#013d87', fontSize:'2rem', lineHeight:'1'}}><b>Let&apos;s Get Started!</b></h1>				
					<div className="text-center mb-0" style={{color:'#013d87', fontSize:"0.7rem"}} >
					  Location:
					  {
					  	((location && location.DisplayLocationName) !== location.City) ?
					  		' '+(location && location.DisplayLocationName)+' ' : ' '	
		  			 					  	
					  }					  
						{(location && location.City)}
						{(location && location.City) ? ', ':''}
						{(location && location.State)} 
{/*
						{ReactGA.send({ hitType: "pageview", page: "/inquiry/"+id, title: 'Inquiry-'+id+': '+location.DisplayLocationName }) }
*/}
							
					</div>
					<div className="text-center mb-0" style={{color:'#013d87', fontSize:"0.7rem", marginTop: "-3px" }} >
						Operator: 
					  {' '+(location && location.FirstName)+' '}
						{(location && location.LastName)}
					</div>
				</Row>
				
				<h3 className="text-center pt-1" style={{color:'#013d87'}}><b>Step 1: Provide Your Contact Information</b></h3>				
{/*
	<button onClick={ShowAlert}>Show Alert</button>
*/}
				<Form autoComplete="off" onSubmit={submitInquiry} className="">
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <RequiredLabel label="First Name" />
                <Form.Control autoFocus required type="text" placeholder="Enter first name" ref={firstName}/>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
            		<RequiredLabel label="Last Name" />
                <Form.Control required type="text" placeholder="Enter  last name" ref={lastName}/>
              </Form.Group>
            </Col>
          </Row>
				  <Form.Group id="email" className="mb-4">
            <RequiredLabel label="Email" />
				    <InputGroup>
				      <InputGroup.Text>
				        <MailIcon className="icon icon-xs text-gray-600" />
				      </InputGroup.Text>
				      <Form.Control required type="email" placeholder="Your email address" ref={email}/>
				    </InputGroup>
				  </Form.Group>
				  <Form.Group id="email" className="mb-4">
            <RequiredLabel label="Phone" />
				    <InputGroup>
				      <InputGroup.Text>
				        <PhoneIcon className="icon icon-xs text-gray-600" />
				      </InputGroup.Text>
				      <Form.Control  required type="tel" placeholder="555-555-5555" onBlur={validatePhone} ref={phone} />
				    </InputGroup>
				  </Form.Group>
				  
				  <Form.Check required defaultChecked className="formControl"
				  	label={
				  		<div style={{fontSize:'0.7rem'}}>
					  		<span>I authorize Point University and its representatives to contact me via SMS. 
					  		I am providing my consent by leaving the opt-in checked. Message and data rates may apply. </span>
					  		<span onClick={()=> setShowTCModal(true)} style={{textDecoration:'underline'}}>Terms of Use</span>
					  	</div>
					  	}
					 	ref={optin} />
				  <div className="d-grid">
        		<Button variant="tertiary" type="submit" 
        			className="mt-2 animate-up-2"
        			style={{fontSize:'inherit', backgroundColor:'#013d87', borderColor:'#3b597c'}}>
        			CONTINUE...
        		</Button>
				  </div>
				</Form>	
				<TCModal showme={showTCModal} closeModal={() => setShowTCModal(false)} />
			</div>
		)
	} else {
		return (
			<>
				<h6 className="text-center">LocationID Required</h6>
				<h6 className="text-center mt-3" style={{fontSize:"0.8rem", fontStyle: 'italic'}}>
					There was not a LocationID provided or the value was not valid.
					Please contact Ficus Education Customer Service for assistance.
				</h6>
			</>
			)
	}

};

const TCModal  = (props) => {
	const {showme, closeModal} = props;
				
	return (
  <Modal centered show={showme} onHide={() => {}} >
    <Modal.Header>
      <Modal.Title >
      	<div>Terms of Use</div>
      	<p className="mb-0">SMS (Short Message Service)</p>
      </Modal.Title>
      <Button variant="close" aria-label="Close" onClick={() => closeModal()} />
  	</Modal.Header>
    <Modal.Body className="mb-1" style={{fontSize:'small'}}>
			<div className="mb-1" >By opting in, you accept and agree to receive marketing messages from the Ficus Group and our associated education partners,
			and you consent to the processing of your information as set forth in the 
			<Button bsPrefix="text" variant="info"  href="https://ficuseducation.com/terms" target="_blank" rel="noopener noreferrer" style={{textDecoration:'underline'}}> privacy policy</Button>. 
			You are further consenting to receive text messages that may be initiated using an auto-dialer. Min 4 msg/month. 
			For details or questions, reply 
			<strong> HELP</strong> to the number from which you received the message.</div>
			
			<div className="mb-1" >Standard carrier message and data rates may apply. Please consult your mobile service carrier&apos;s pricing plan 
			 to determine the charges for sending and receiving text messages.</div>
			
			<div className="mb-1" ><strong>How to opt out:</strong> You can opt out from future messages at any time by texting 
			<strong> STOP, END, CANCEL, UNSUBSCRIBE, QUIT, or STOP ALL</strong> to the number from which you received the message. 
			If you request to opt out from future messages, we may send you a one-time opt out confirmation text message.</div>    
		</Modal.Body>
    <Modal.Footer>
      <Button variant="info" size="sm" onClick={() => closeModal()}>
      	Close
      </Button>
    </Modal.Footer>
  </Modal>  
  )	
};
		
export default () => {
  return (
    <main>
      <section className="d-flex overflow-scroll vh-lg-100 mt-lg-0 bg-soft">
        <Container>
					<Row className="justify-content-center">
						<Col xs={12} sm={8} md={6} >
{/*
 		        	<div className="text-center text-md-center mt-md-0 mx-n3">
			          <img src={HeroImage} alt={OrganizationName}  />
			        </div>
*/}
        			<FicusInquiryForm showLocation={true}/>
							<Row className="justify-content-center">
								<Col xs={11} className="text-center py-4" style={{fontSize: "0.7rem", fontStyle: "italic"}} >
										<div>Learn more about Ficus Education&apos;s</div>         		
					          <a href="https://ficuseducation.com/student-journey" target="_blank" rel="noopener noreferrer" className="ms-n1" style={{textDecoration:"underline"}}>
					          	Employer Sponsored Higher Education program...
{/*
					          	<img src={FicusLogo} alt="Ficus Education" title="Ficus Education Student Journey" style={{maxWidth: "200px"}}/> 
*/}
					          </a>
		        		</Col>
							</Row>
        		</Col>
        	</Row>
        </Container>
      </section>
    </main>
	);
};
