
import React, { useState } from "react";
import { Helmet } from 'react-helmet';

import { LockClosedIcon, MailIcon } from "@heroicons/react/outline";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup, Alert } from 'react-bootstrap'; 
import { Link, useNavigate } from 'react-router-dom';

import { myRoutes } from "routes";

import { useAuth } from "pages/access/useAuth"; 

// JKG Ficus Specific Starts Here
import FicusLogo from "assets/img/logos/FicusEducation_Logo_ADMIN_CMYK.png";
// JKG - Make dynamic, driven from organizationID passed in by URL
import OrganizationLogo from "assets/img/logos/5000_Futures.jpg"; 

const FicusHelpPhone = "888-344-2053"
const OrganizationName = "5000 Futures"
const OrganizationURL = "5000Futures.org"

export const FicusSignInForm = (props) => {
	const {admintype} = props;
	const accessLabel = (admintype === 'admin') ? 'Admin' : 'Partner';
	
	const auth = useAuth();
	const [acctStatus, setAcctStatus] = useState('new');
	const navigate = useNavigate();
	
	const refEmail = React.createRef(); 		 
	const refPwd = React.createRef(); 		
		
	const handleSubmit = (event) => {			
	  event.preventDefault();

	  auth.signIn({email: refEmail.current.value, pwd: refPwd.current.value, persontype: (admintype === 'admin') ? '100' : '101'})
		.then(response => {
			setAcctStatus(response);
			if (response === 'active') {
//						window.open("/dashboard/dashboardoperator.js", "_blank", "noreferrer")
				navigate('/access/FicusAdminHome.js', {replace: true});//
			}
		});			  
	}
	
	return (
		<div>	
			<Helmet>
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
			</Helmet>
			
			{ (acctStatus === 'active') && ( navigate('/dashboard/dashboardoperator.js', {replace: true}) ) }
	
			{ (acctStatus === 'resetpwd') &&  ( 
				  navigate('/access/reset-password', {replace: true})							
				)
			}

			{ (acctStatus === 'invalid') &&  ( 
				  <Alert variant="danger">
				    Could not signin.  Please check the email address and password and try again.
				  </Alert>							
				)
			}
			
			{ (acctStatus === 'inactive') && (
					<Alert variant="danger">
				    The Email and Password you entered did not match an active account.  Please try again or contact Customer Service at {FicusHelpPhone}.
				  </Alert>
				)
			}
			<Form onSubmit={handleSubmit} className="mt-4">
			  <Form.Group id="email" className="mb-4">
			    <Form.Label>{accessLabel} Email</Form.Label>
			    <InputGroup>
			      <InputGroup.Text>
			        <MailIcon className="icon icon-xs text-gray-600" />
			      </InputGroup.Text>
			      <Form.Control ref={refEmail} autoFocus required type="email" placeholder="example@company.com" />
			    </InputGroup>
			  </Form.Group>
			  <Form.Group>
			    <Form.Group id="password" className="mb-4">
			      <Form.Label>{accessLabel} Password</Form.Label>
			      <InputGroup>
			        <InputGroup.Text>
			          <LockClosedIcon className="icon icon-xs text-gray-600" />
			        </InputGroup.Text>
			        <Form.Control ref={refPwd} required type="password" placeholder="Password" />
			      </InputGroup>
			    </Form.Group>
			    <div className="small d-flex justify-content-between align-items-top mb-4">
			      <Form.Check type="checkbox">
			        <FormCheck.Input id="defaultCheck5" className="me-2" />
			        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me on this device</FormCheck.Label>
			      </Form.Check>
			    </div>
			  </Form.Group>
			  <div className="d-grid">
			    <Button variant="primary" type="submit">
			      Sign in
			    </Button>
			  </div>
			</Form>	

		</div>
	)
};



export default () => {
		const auth = useAuth();


	  return (
	    <main>
	      <section className="d-flex align-items-center vh-lg-100 mt-2 mt-lg-0 bg-soft">
	        <Container>
	          <Row className="justify-content-center">
	            <Col xs={12} className="d-flex align-items-center justify-content-center"> 
	              <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
	                <div className="text-center text-md-center mt-n4">
					          <img src={FicusLogo} alt="Ficus Education" title="Ficus Education WebSite" /> 
	                </div>
	                <div className="text-center text-md-center mb-4 mt-n4">
	                	<h4 style={{color:'#c00000', fontWeight:'bold'}}>Ficus Administrator Access</h4>
		              </div>
	                <FicusSignInForm admintype='admin' />

							    <div className="d-flex justify-content-center align-items-top mt-2 mb-4">
							      <Card.Link as={Link} to={myRoutes.ResetPassword.path} className="small text-end">Sign in problems? Click here!</Card.Link>
							    </div>
	              </div>
	            </Col>
	          </Row>
	        </Container>
	      </section>
	    </main>
	  );
}



