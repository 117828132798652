import React from "react";
import { Col, Row } from 'react-bootstrap';
import { GeneralInfoForm } from "components/FicusForms";

export default () => {

  return (
	<>
		<Row className="justify-content-center">
			<Col xs={12}>
				<GeneralInfoForm />
			</Col>
		</Row>
	</>
  );
};
