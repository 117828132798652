
import { v4 as uuidv4 } from "uuid";
import moment from "moment-timezone";
import { ExclamationIcon, InboxIcon, RefreshIcon } from "@heroicons/react/solid";

import { myRoutes } from "routes";


export const userNotifications = [
    {
        "id": uuidv4(),
        "read": true,
        "time": null,
        "link": myRoutes.Notifications.path,
        "message": `No new notifications`
    }
];

export const productNotifications = [
    {
        "id": uuidv4(),
        "title": "New message",
        "time": moment().subtract(8, "minutes"),
        "icon": InboxIcon,
        "iconBg": "primary",
        "message": "Let's meet at Starbucks at 11:30. Wdyt?",
    },
    {
        "id": uuidv4(),
        "title": "Product issue",
        "time": moment().subtract(10, "minutes"),
        "icon": ExclamationIcon,
        "iconBg": "warning",
        "message": "A new issue has been reported for Pixel Pro.",
    },
    {
        "id": uuidv4(),
        "title": "Product update",
        "time": moment().subtract(4, "hours"),
        "icon": RefreshIcon,
        "iconBg": "success",
        "message": "Spaces - Listings Template has been updated.",
    },
    {
        "id": uuidv4(),
        "title": "Product update",
        "time": moment().subtract(5, "hours"),
        "icon": RefreshIcon,
        "iconBg": "success",
        "message": "Volt - Admin Dashboard has been updated.",
    },
];