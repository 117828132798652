
import React from "react";
import { Card } from 'react-bootstrap';
import AccordionComponent from "components/AccordionComponent";


export default () => {
	return (
		<>
		<h5 className="mb-4 mt-4">Frequently Asked Questions</h5>
		<Card className="faq">
		<AccordionComponent
			  defaultKey="panel-1"
			  data={[
			    {
			      id: 1,
			      eventKey: "panel-1",
			      title: "What types of degrees can employees pursue through Ficus Group?",
			      description: "Ficus Group Network of Educational Partners offers a wide range of degree programs that employees can pursue, from associate degrees to graduate degrees. Our partner universities and colleges offer programs in various fields such as business, technology, education, and more. Our team works closely with each employer to understand their workforce needs and design a customized educational assistance program that meets their goals. We ensure that the degree programs we offer are from accredited institutions and meet high academic standards."
			    },
			    {
			      id: 2,
			      eventKey: "panel-2",
			      title: "Can employers offer this benefit to part-time employees or only full-time employees?",
			      description: "Yes, employers can offer this benefit to both part-time and full-time employees. Under Section 127 of the Internal Revenue Code, employers can provide up to $5,250 per year in tax-free educational assistance to their employees, regardless of whether they are part-time or full-time. This means that even employees who work part-time or have other obligations can take advantage of this benefit and further their education. "
			    },
			    {
			      id: 3,
			      eventKey: "panel-3",
			      title: "What is the time committment required by employees enrolled in a program?",
			      description: "Ficus Group's program is designed to be flexible and accessible, with online courses and programs that can be completed at the employee's own pace. This allows employees to balance their work and other responsibilities with their studies, making education more attainable and convenient for all employees, regardless of their work schedule."
			    },
			    {
			      id: 4,
			      eventKey: "panel-4",
			      title: "Who is Point University?",
			      description: "Point University is a private, Christian university in West Point, Georgia that offers undergraduate and graduate degrees in various fields such as business, criminal justice, education, ministry, psychology, and more. The university also offers several online programs for working professionals."
			    },
			    {
			      id: 5,
			      eventKey: "panel-5",
			      title: "Is Point University accredited?",
			      description: "Yes, Point University is accredited by the Southern Association of Colleges and Schools Commission on Colleges (SACSCOC), which is the regional accrediting body for colleges and universities in the southern United States. Accreditation ensures that the university meets certain standards for academic quality and institutional effectiveness."
			    }
			  ]} />
		</Card>
		<div className="ms-2 mt-2" style={{fontSize:'9px'}}>
		<i>Ficus Dashboard version 1.82</i>
		</div>
		</>
	)
};
