import React, { useState, useEffect } from "react";
import { MailIcon, ChatIcon, DocumentDuplicateIcon } from "@heroicons/react/solid";
import { Col, Row, Card, Image, Button } from 'react-bootstrap';
import { LocationDropDownWidget } from "components/FicusWidgets.js";
import { getBlobFromImageElement, copyBlobToClipboard } from 'copy-image-clipboard'

import { useAuth } from "pages/access/useAuth"; 

const OrganizationName = "5000 Futures"

const FicusQRCodeCard = () => { 
	const { user, curLocation } = useAuth();
	const [qrCodeURL, setQRCodeURL] = useState();
	const [linkURL, setLinkURL] = useState();
	
	useEffect(() => {
		if (curLocation) {
			setLinkURL(`https://portal.ficuseducation.com/inquiry/${curLocation.locationid}`);
			setQRCodeURL(`https://portal.ficuseducation.com/inquiry/${curLocation.locationid}`) 		
		} else {
			setQRCodeURL(null)			
		}
	},[user, curLocation]);

/*
const snapshotCreator = () => {
  return new Promise((resolve, reject) => {
    try {
      const scale = window.devicePixelRatio;
      const element = document.getElementById('qrcodeimage'); // You can use element's ID or Class here
      domtoimage
        .toBlob(element, {
          height: element.offsetHeight * scale,
          width: element.offsetWidth * scale,
          style: {
            transform: "scale(" + scale + ")",
            transformOrigin: "top left",
            width: element.offsetWidth + "px",
            height: element.offsetHeight + "px",
          },
        })
        .then((blob) => {
          resolve(blob);
        });
    } catch (e) {
      reject(e);
    }
  });
};


	async function copyToClipboard() {
	    try {
	      await navigator.clipboard.write([
	        new window.ClipboardItem({
	          "image/png": new Promise(async (resolve, reject) => {
	            try {
	              const blob = await snapshotCreator();
	              resolve(new Blob([blob], { type: "image/png" }));
	            } catch (err) {
	              reject(err);
	            }
	          }),
	        }),
	      ])
	      alert("Image copied");
	    } catch (error) {
	        console.error(error);
	    }
	}

*/

async function copyQRCode() {
	const imageElement = document.getElementById('qrcodeimage');
	getBlobFromImageElement(imageElement)
	  .then((blob) => {
	    return copyBlobToClipboard(blob)
	  })
	  .then(() => {
	    alert('QR Code Image Copied')
	  })
	  .catch((e) => {
	    alert('Error: ', e.message)
	  })
}


	
	if (!curLocation) {
		return (
	    <Card  className="">
	      <Card.Body className="text-center">
      		<LocationDropDownWidget initialText="Select a Location"/>
					<p className="mt-3" style={{fontSize: 'small', lineHeight:'1rem', color:'crimson'}}>Please select a location. The Student Inquiry form is specific to your store 
						locations so that you can track activity.</p>
	      </Card.Body>
	    </Card>			

		)
	} else {
		return (
	    <Card  className="">
	      <Card.Body>
      		<LocationDropDownWidget  initialText="Select a Location"/>
      		<div className="mt-2 mx-n2">
						<Button variant="default" href={`sms:?&body=${linkURL}`} size="sm" target="_blank">
		        	<ChatIcon className="icon icon-xs" color="rgba(115,164,11,100%)"/>
							<div className="small">Message</div>
						</Button>
						<Button variant="default" href={`mailto:?&body=Click here to get more information ${linkURL}&subject=${OrganizationName} Free College Tuition`} target="_blank">
		        	<MailIcon className="icon icon-xs" color="rgba(115,164,11,100%)"/>
							<div className="small">Email</div>
						</Button>
						<Button variant="default" size="sm">
		        	<DocumentDuplicateIcon className="icon icon-xs" color="rgba(115,164,11,100%)" onClick={() => navigator.clipboard.writeText(linkURL)}/>
							<div className="small">Copy Link</div>
						</Button>
					</div>
					<hr />

{/*
	        <Button href={`sms:?&body=${linkURL}`} size="sm"  variant="tertiary" target="_blank">
	        	<ChatIcon className="icon icon-xs me-1" />Send Inquiry Form via Text
	        </Button>
	        <Button href={`mailto:?&body=Click here to get more information ${linkURL}&subject=${OrganizationName} Free College Tuition`} size="sm"  variant="tertiary" target="_blank">
	        	<MailIcon className="icon icon-xs me-1" />Send Inquiry Form via Email
	        </Button>

	        <h6 className="fw-normal text-center mt-3">QRCode and URL for {(curLocation && curLocation.locationname)}</h6>
*/}
					<Image id="qrcodeimage" src={`https://quickchart.io/qr?text=${qrCodeURL}`} />
	        <div className="text-center small mt-n2">
	          <Button bsPrefix="text" href="" variant="gray-700" style={{fontSize:"0.9rem"}}>Copy QR Code 
	          	<DocumentDuplicateIcon className="icon icon-xs ms-1" color="rgba(115,164,11,100%)" 
	          		onClick={() => copyQRCode()} />
	          </Button>
	        </div>
{/*
						<input type="text" className="w-100" disabled style={{fontSize:"0.7rem"}} value={linkURL} />
	        <div className="text-center mb-4 mt-n1">
	          <Button bsPrefix="text" href="" size="sm"  variant="gray-700" style={{fontSize:"0.8rem", fontStyle: 'italic'}}>Copy Link URL 
	          	<DocumentDuplicateIcon className="icon icon-xxs ms-1 mt-n1" style={{strokeWidth:"1.0", height:"0.8rem"}} onClick={() => navigator.clipboard.writeText(linkURL)} />
	        	</Button> 
	        </div>
*/}
	      	<hr/>
	     	 <Button href={linkURL} size="sm"  className="ficusgreen px-4 py-1 my-2" target="_blank">Go to Inquiry Form...</Button>
	      </Card.Body>
	    </Card>			
			)
	}

};

export default () => {
  return (
	<>
		<Row className="justify-content-center mt-2 ">
			<Col xs={12} sm={8} md={6}  className="justify-content-center ">
				<h5 className="ms-4 mt-2 text-center">Share the Information Request Form</h5>
				<p className="small" style={{lineHeight:'1.2rem'}}>Share a link to the Information Request form or allow someone to scan the QR Code right from your phone!
				</p>
			</Col>
		</Row>
{/*
		<Row className="justify-content-center mb-2">
			<Col xs={10} sm={6} md={4} xl={2} className="text-center">
				<LocationDropDownWidget />
			</Col>
		</Row>
*/}
		<Row className="justify-content-center">
			<Col xs={12} sm={8} md={6}  className="text-center">
				<FicusQRCodeCard />
			</Col>
		</Row>
{/*
		<Row className="justify-content-center mt-2">
			<Col xs={10} sm={6} md={4} xl={2} className="text-center">
				<FicusInquiryLinkCard />			
			</Col>
		</Row>
*/}
	</>
  );
};
