
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useMediaQuery } from 'react-responsive'
import { AcademicCapIcon, LocationMarkerIcon } from "@heroicons/react/outline";
import { Row, Col, Card, Image } from 'react-bootstrap';
import CountUp from 'react-countup';

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { icon } from "leaflet";

import { useAuth } from "pages/access/useAuth"; 

import OrganizationLogo from "assets/img/logos/5000_Futures.png"; 

const OrganizationName = "5000 Futures"

export const LocationsMap = (props) => { 
	const { endpoint } = useAuth();
	const [ storeLocations, setStoreLocations ] = useState([]);
  const isMobile = useMediaQuery({ maxDeviceWidth: 440 });

	const MapIcon = isMobile ?  icon({
					  iconUrl: require('assets/img/icons/map-pin-red-25x43.png'),
					  iconSize: [9, 14], // size of the icon
					  shadowSize: [8, 10], // size of the shadow
					  iconAnchor: [5, 7], // point of the icon which will correspond to marker's location
					  shadowAnchor: [4, 7],  // the same for the shadow
					  popupAnchor: [-3, -7] // point from which the popup should open relative to the iconAnchor
					})
				 :  icon({
					  iconUrl: require('assets/img/icons/map-pin-red-25x43.png'),
					  iconSize: [10, 16], // size of the icon
					  shadowSize: [8, 12], // size of the shadow
					  iconAnchor: [6, 15], // point of the icon which will correspond to marker's location
					  shadowAnchor: [4, 12],  // the same for the shadow
					  popupAnchor: [-3, -12] // point from which the popup should open relative to the iconAnchor
					});				
				
  const initialHeight = isMobile ? "200px" : "375px";  
  const initialZoom = isMobile ? 3 : 4;

  
  
	const mapConfig = {
	  maxZoom: 18,
	  id: "mapbox/light-v10",
	  url: "http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
	  attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="http://cartodb.com/attributions">CartoDB</a>',
	  opacity:1,
/*
	  url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
	  attribution: '<span style="font-size: 8px;">Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a></span>',
*/
	  accessToken: "pk.eyJ1Ijoiem9sdGFudGhlbWVzYmVyZyIsImEiOiJjazZqaWUwcWswYTBvM21td2Jmcm5mYmdyIn0.7_5YCbbOFRnvqZzCNDo9fw"
	};

	useEffect(() => {
	  axios.get(endpoint+"/company/stats/locations/default.asp", {
			  		params: { }	
		  })  
		  .then(response => {
		  	const theResult = response.data;
		  	setStoreLocations(theResult);
		  })
	  	.catch(error => console.log(error));	
// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

	return (
	<>
		<Row className="justify-content-center align-items-center mt-2 pb-1 pt-2 px-3" style={{backgroundColor:"rgb(212 218 220)", borderTopLeftRadius: "0.5rem", borderTopRightRadius: "0.5rem"}}>
			<Col xs={7} md={5} style={{maxHeight: "110px"}}>
				<Image src={OrganizationLogo} alt={OrganizationName} fluid style={{ width: "100%"}} />			
			</Col>
			<Col xs={5} md={6}>
				<Row>
					<Col xs={6}  >
			      <Card className="mb-0 text-center" style={{color:'#666', border:0, backgroundColor:'transparent'}}>
			        <Card.Body className="pb-1 pt-0 px-0 d-flex">
			          	<Card.Title  className="h6 mb-n1" style={{color:'#666'}}>
				          	<AcademicCapIcon className="icon icon-xxs me-1" />
				          	<small style={{fontSize: "0.75rem"}} ><b><CountUp start={0} duration={4.0} end={storeLocations.reduce((n, {studentcount}) => n + studentcount, 0)} /></b> Students</small>				          
			          	</Card.Title>
			        </Card.Body>
			      </Card>
			    </Col>
					<Col xs={6} >
				     <Card className=" me-2 mb-0 text-center" style={{color:'#666', border:0, backgroundColor:'transparent'}}>
			        <Card.Body className="py-0 px-0 d-flex" style={{backgroundColor:'transparent'}} >
				          <Card.Title className="h6 mb-n1" style={{color:'#666'}}>
			            	<LocationMarkerIcon className="icon icon-xxs me-1" />
				          	<small style={{fontSize: "0.75rem"}} ><b><CountUp start={0} duration={2.5} end={storeLocations.reduce((n, {studentcount}) => n + 1, 0)} /></b> Locations</small>
				          </Card.Title>		          
			        </Card.Body>
			      </Card>
		    	</Col>
		    </Row>
	    </Col>
	  </Row>
	    <Row className="justify-content-center mx-n4" >
	    	<Col xs={12}>
		 	    <MapContainer 
		 	    	id="ficusmapbox" 
		 	    	style={{height:initialHeight, maxWidth:"700px"}}
		 	    	className="" 
						zoomControl={false}
		 	    	center={[39.8283, -98.5795]} 
		 	    	zoom={initialZoom}>
			      <TileLayer {...mapConfig} />

			      {storeLocations.map(sl => {
			       if ( Boolean(sl.Latitude) && Boolean(sl.Longitude)) {
				       	return (
				        <Marker key={`map-marker-${sl.LocationID}`} position={[sl.Latitude, sl.Longitude]} icon={MapIcon}>
				          <Popup>
				            <Card className="card-article-wide border-0 flex-column no-gutters no-hover">
				              <Card.Body className="py-0 d-flex flex-column justify-content-center col-12 px-0
				              ">
				                <div className="font-small mb-0" style={{lineHeight:"0.6rem", fontSize:"0.6rem"}}>{sl.LocationName}</div>
				                <div className="font-small mb-0" style={{lineHeight:"0.6rem", fontSize:"0.6rem"}}>{sl.City+', '+sl.State}</div>
	{/*
				                <div className="d-flex justify-content-center">
				                  <AcademicCapIcon className="icon icon-xs icon-tertiary me-2" />
				                  <div className="font-xs text-dark">Students {sl.studentcount}</div>
				                </div>
	*/}
				              </Card.Body>
				            </Card>
				          </Popup>
				        </Marker> )
				      	} else {
				      		return('')
				      	}
			      	
			      	}
			      )}
			    </MapContainer>	
			  </Col>
			</Row>
		</>
  )
}


