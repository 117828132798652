
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { Col, Row, Card, Form, Image, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { useAuth } from "pages/access/useAuth"; 


export const ContactForm = (props) => { 	
	const { 
		user,
		curLocation,
		secToken,
		endpoint,
		proxy
	} = useAuth(); 	
	
	const {thePersonID} = props;
	const [showParticipants, setShowParticipants] = useState((thePersonID) ? true : false);
	const [students, setStudents] = useState([]); 
	
	const subject = useRef("");
	const email = useRef("");
	const content = useRef("");
	
	const MessageSent = withReactContent(Swal.mixin({
	  customClass: {
	    confirmButton: 'btn btn-primary me-3',
	    cancelButton: 'btn btn-gray'
	  },
	  buttonsStyling: false
	}));

	let navigate = useNavigate(); 
	
	/* Get the student list */
	useEffect(() => {
	  axios.get(endpoint+"/student/", {
  		params: { 
  			'companyid': user.companyid 
/*
				 for the time being, just get a list of all students without regard to location 
  			'locationid': curLocation && curLocation.locationid
*/
  			}	
		  })  
		  .then(response => {
		  	const theResult = response.data;
		  	console.log(theResult);
		  	setStudents(theResult);
		  })
	  	.catch(error => console.log(error));	
	},[user, curLocation, endpoint]);


	function sendMesssage() {
		var ticket = 
	  { "properties": {
		    "hs_pipeline":"0",
		    "hs_pipeline_stage":"1",
		    "hs_ticket_priority": "HIGH",
				"subject": subject.current.value,
				"source_type": "OperatorDashboard", 
				"external_email": email.current.value,
				"content": content.current.value
			}
		}
		
		let formdata = new URLSearchParams({'post_body': JSON.stringify(ticket)}).toString();

		axios.post(proxy, formdata, {
				params: { 
					'sectoken': secToken, 
					'url':"https://api.hubapi.com/crm/v3/objects/tickets", 
					'status_only_ind':'No', 
					'Content_Type':'application/json',
					'authorization_header': 'Bearer pat-na1-5e11e810-64bd-4933-867f-846d6c66e09b' }	
			})  
		  .then(response => {
				const newid = response.data.id;	        
				console.log("New Ticket ID: "+newid);	
				if (newid) {
					const assn = {
						inputs:[
							{from:{id:newid},
							to:{id:user.hscontactid},
							type: "ticket_to_contact"}			

						]}							
					
					let assndata = new URLSearchParams({'post_body': JSON.stringify(assn)}).toString();

					axios.post(proxy, assndata, {
						params: { 
							'sectoken': secToken, 
							'url':"https://api.hubapi.com/crm/v3/associations/Tickets/Contacts/batch/create", 
							'status_only_ind':'No', 
							'Content_Type':'application/json',
							'authorization_header': 'Bearer pat-na1-5e11e810-64bd-4933-867f-846d6c66e09b' }	
					})
					.then(response => {
							console.log("Assn Created");							
							ShowAlert();				
					})
					.catch((err) => {
	        	console.error(err);
	  			})
	  
				}
	        
		  })
	    .catch((err) => {
	        console.error(err);
	        alert('HS API Issue: '+err);
	  	})
	};  		
  

  async function ShowAlert() { 
		await MessageSent.fire({
			icon: 'success',
			title: 'Your Message was Sent',
			text: 'One of our reps will be back to you quickly!',
			confirmButtonText: 'Return to Dashboard!',
  	}).then((result) => {
  		navigate('/dashboard/dashboardoperator.js')  		
  	});
	}

  const onTopicChange = e => {
  	const newTopic = e.target.value;
		subject.current.value = newTopic;
		subject.current.disabled = (newTopic === '') ? false : true;
		content.current.value = '';
		setShowParticipants(newTopic === 'Team Member Status');
		
  }
  
  const onParticipantChange = e => {
  	updateParticipant(students.find((rec) => { return rec.PersonID === e.target.selectedOptions[0].value; }));
	}

	function updateParticipant(personinfo)  {
		subject.current.value = 'Status Request: '+personinfo.FirstName+' '+personinfo.LastName;
		content.current.value = 'Type your question here...';
		content.current.value += '\r\n\r\n Team Member: '+personinfo.FirstName+' '+personinfo.LastName;
		content.current.value += '\r\n Status: '+personinfo.StudentSummaryStatus+' - '+personinfo.StudentStatus;
		content.current.value += '\r\n Last Status Update: '+personinfo.AssignedDate;
		content.current.value += '\r\n FAFSA: '+((personinfo.FAFSA) ? personinfo.FAFSA : 'Not Processed') ;
		content.current.value += '\r\n Transcript: '+((personinfo.TMA) ? personinfo.TMA : 'Not Processed') ;
		content.current.value += '\r\n\r\n Operator: '+personinfo.CompanyName;
		content.current.value += '\r\n Location: '+personinfo.LocationName;

		content.current.focus();
		content.current.setSelectionRange(0, 26);
		content.current.scrollTop = 0;
		content.current.scrollLeft = 0;

  }
  
 	const SelectOption = (props) => {
		const { PersonID, FirstName, LastName } = props;     
/*  THis needs to go in a did load function... */
		useEffect(() => {
			if ( thePersonID && students ) { updateParticipant(students.find((rec) => { return rec.PersonID === thePersonID; })) };
		},);

    return (
      <option value={PersonID}  >
      	{FirstName+' '+LastName}
      </option>
    )
  }

  function SelectParticipant() {		

  	return (
	    <Row>
	      <Col  className="mb-3">
	        <Form.Group className="mb-2">
	          <Form.Label>Select Team Member</Form.Label>
	          <Form.Select id="participant" defaultValue={(thePersonID) ? thePersonID : 0 } onChange={onParticipantChange}>
	            <option value="0">None Selected...</option>
	        		{students.map(c => <SelectOption key={`persons-${c.PersonID}`} {...c} />) }
	          </Form.Select>
	        </Form.Group>
	      </Col>
	    </Row> 
    )
  }
      
  return (
    <Card className="mb-0">
      <Card.Body className="pt-1">
        <Form id="myform">
          <Row className="d-none">
            <Col className="mb-3">
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control disabled readOnly type="email" placeholder="Email Not Found" ref={email} value={user.personalemail}/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="mb-3">
              <Form.Group >
                <Form.Label>How can we help?</Form.Label>
							  <fieldset id="topic" className="small ms-3">
							    <Form.Check
							    	defaultChecked = {thePersonID !== undefined}
							      type="radio"
							      value="Team Member Status"
							      label="Team Member Status"
							      name="evoptions"
							      id="Team Member Status"
							      htmlFor="Team Member Status"
						        onChange={onTopicChange}
							      />

							    <Form.Check
							      type="radio"
							      value="Ficus Program Question"
							      label="Ficus Program Question"
							      name="evoptions"
							      id="Program Question"
							      htmlFor="Program Question"
						        onChange={onTopicChange}
							      />

							    <Form.Check
							      type="radio"
							      value="Dashboard Tech Support"
							      label="Dashboard Tech Support"
							      name="evoptions"
							      id="Technical Support"
							      htmlFor="Technical Support"
						        onChange={onTopicChange}
							      />

							    <Form.Check
							      type="radio"
							      value=""
							      label="Other"
							      name="evoptions"
							      id="Other"
							      htmlFor="Other"
						        onChange={onTopicChange}
							      />

							  </fieldset>
	            </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="mb-3">
              <Form.Group id="subject">
                <Form.Label>Subject</Form.Label>
                <Form.Control required type="text" placeholder="Enter your subject" ref={subject}/>
              </Form.Group>
            </Col>
          </Row>
					{ showParticipants && <SelectParticipant /> }

          <Row>
            <Col  className="mb-3">
              <Form.Group id="content">
                <Form.Label>Message</Form.Label>
                <Form.Control required as="textarea" rows={5} placeholder="" ref={content} wrap="off" />
              </Form.Group>
            </Col>
          </Row>
          <Row className="">
            <Col  className="d-grid mb-3">
	        		<Button variant="primary" type="button" onClick={sendMesssage} className="mt-2 animate-up-2">
	        			Send Message
	        		</Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};


export const DropFilesForm = () => {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: files => setFiles(files.map(file => ({
      ...file,
      preview: URL.createObjectURL(file)
    })))
  });

  const DropzoneFile = (props) => {
    const { path, preview } = props;

    return (
      <Col xs={6} className="dropzone-preview">
        <Image src={preview} className="dropzone-image" />
        <Card.Text className="dropzone-filename">
          {path}
        </Card.Text>
      </Col>
    );
  };

  return (
    <>
      <Form {...getRootProps({ className: "dropzone rounded d-flex align-items-center justify-content-center mb-4" })}>
        <Form.Control {...getInputProps()} />
        <div className="dz-default dz-message text-center">
          <p className="dz-button mb-0">Drop files here to upload</p>
        </div>
      </Form>
      <Row className="dropzone-files">
        {files.map(file => <DropzoneFile key={file.path} {...file} />)}
      </Row>
    </>
  );
};
