
import React, {useState}  from "react";

import { Col, Row, Card, Container, Button, Image } from 'react-bootstrap';
import { StoryWidget, VerficationWidget, CallToAction } from "components/FicusWidgets";
import { StatusDetailPieChart2 } from "components/FicusChartWidgets";
import { StudentListCard, GraduatesListCard } from "components/FicusTables.js";
// import { LocationsMap } from "components/FicusMaps.js"; 
import { StudentProcessModal } from "components/FicusModals.js";

import { useAuth } from "pages/access/useAuth"; 


export default () => {
	const { 
		curStatusGroup
	} = useAuth(); 	

	const [showProcessModal, setShowProcessModal] = useState(false);

  return (
      <Container className="d-block px-0">
{/*
	      <Row>
        <Col xs={12} sm={6} md={5} xl={4} className="align-items-center mb-4" >
      		<XLocationListWidget />
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={6} md={5} xl={4} className="align-items-center mb-4" >
      		<LocationListWidget />
        </Col>
      </Row>
*/}
      <Row className="align-items-center justify-content-md-center ">
        <Col xs={12} md={10}  className="" >
      		<VerficationWidget />
        </Col>
			</Row>

      <Row className="align-items-center justify-content-md-center ">
        <Col xs={12} md={10}  className="" >
      		<StoryWidget />
        </Col>
			</Row>
      <Row className="align-items-center justify-content-md-center ">
        <Col xs={12} md={6} xl={6} className=" mt-n2 mb-3" >
        	<Card border="0" className="mx-n2">
{/*
        		<Card.Header className={`py-1 ${curStatusGroup.toLowerCase()}`}>
        			<div className="ms-n2 mb-0">
								<StoryCardIcon className="icon icon-sm me-1" style={{strokeWidth:1, verticalAlign:'bottom'}} />
								 {curStatusGroup} Status Overview
							</div>
        		</Card.Header> 
        		<Card.Body className="p-0" style={{maxHeight:"175px"}}>
	      			<StatusDetailPieChart /> 
        		</Card.Body>
*/}
        		<Card.Body className="p-0 mt-n3" style={{maxHeight:"175px"}}>
	      			<StatusDetailPieChart2 /> 
        		</Card.Body>
      		</Card>
        </Col>
			</Row>

      <Row className="align-items-center justify-content-md-center ">
        <Col xs={12} md={10} className="mb-4" >
      		<StudentListCard sortorder="created" ficusstatus={curStatusGroup} showcurrentonly='true'/>
        </Col>
			</Row>
			
      <Row className="align-items-center justify-content-md-center ">
        <Col xs={12} md={10} className="mb-4" >
      		<GraduatesListCard />
        </Col>
			</Row>
			
			
{/*
      <Row className="align-items-center justify-content-md-center ">
        <Col xs={12} md={6} xl={6} className="" >
        	<Card className="mx-n2">
        		<Card.Header className="py-1 border-bottom-0 enrolled">
        			<div className="ms-n2 mb-0 " >
								<EnrolledIcon className="icon icon-sm me-1" style={{strokeWidth:1, verticalAlign:'bottom'}} />
        				Enrollment Status
        			</div>
        		</Card.Header> 
        		<Card.Body className="px-4 py-1 text-center">
        			<div style={{width:"70%", margin:"0 auto", paddingTop:"2rem", paddingBottom:"1rem"}}>
	      				<StatusDetailPieChartX staticstatus="Enrolled"/> 
	      				</div>
        		</Card.Body>
      		</Card>
        </Col>
			</Row>
*/}
{/*      <Row className="align-items-center justify-content-md-center ">
        <Col xs={12} sm={6} md={5} xl={4} className="mb-4" >
        	<Card className="mx-n2">
        		<Card.Header className="py-1">
        			<h6 className="mb-0">Enrollment Status</h6>
        		</Card.Header> 
        		<Card.Body>
	      			<StatusPieChart /> 
        		</Card.Body>
      		</Card>
        </Col>
			</Row>
*/}

{/*
			<hr  style={{
			    color: '#808080',
			    backgroundColor: '#808080',
			    height: 2.5,
			    borderColor : '#808080',
			    marginLeft: '-4rem',
			    marginRight: '-4rem',
			}}/>
*/}

      <Row className="align-items-center justify-content-md-center ">
        <Col xs={12} sm={10} md={8}>
        	<CallToAction />
        </Col>
			</Row>

{/*
	      <Row className="align-items-center justify-content-md-center ">
        <Col xs={12} md={10} >
     				<LocationsMap />      				
        </Col>
			</Row>
*/}
      <StudentProcessModal show={showProcessModal} onHide={() => {setShowProcessModal(false)}}/>
    </Container>
  );
};

