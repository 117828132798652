import React from "react";
import { useLocation } from 'react-router-dom';
import { Col, Row, Button } from 'react-bootstrap';
import { ContactForm } from "components/FicusContactForm";
import { MailIcon, PhoneIcon } from "@heroicons/react/outline";

 
export default (props) => {
	/* Check to see if a personid is passed in from navigating one of the participant tables */
	const { state } = useLocation();
	const { PersonID } = state || {};

  return (
	<>
		<Row className="justify-content-center">
			<Col xs={12} sm={8} md={6} className="small" >
        <h4 className="my-2">Contact Us</h4>
        Call us or send a message using the form below.
        <div className="mt-1">
				  Customer Service: 
				  <Button variant="default" className="text-start mt-n1 p-0 small" href={'tel:8883442053'} target="_blank" style={{color:"rgba(115,164,11,100%)"}}>
				    <PhoneIcon className="icon icon-xxs ms-1" color="rgba(115,164,11,100%)"/> 888-344-2053
				  </Button>
			  </div>
			</Col>
		</Row>
		<Row className="justify-content-center mt-3">
			<Col xs={12} sm={8} md={6}>
				<ContactForm thePersonID = {PersonID} />
			</Col>
		</Row>
		<Row className="justify-content-center">
			<Col xs={12} sm={8} md={6} className="mt-2 small">
				Email: 
			  <Button size="sm" variant="default" className="text-start mt-n1 p-0 small" href={'mailto:info@ficuseducation.com?subject=Store Operator Message'} target="_blank" style={{color:"rgba(115,164,11,100%)"}}>
			    <MailIcon className="icon icon-xxs ms-1" color="rgba(115,164,11,100%)"/> info@ficuseducation.com
			  </Button>
			</Col>
		</Row>
	</>
  );
};
