
// =========================================================
// * Volt Pro React Dashboard
// =========================================================

// * Product Page: https://themesberg.com/product/dashboard/volt-pro-react
// * Copyright 2021 Themesberg (https://www.themesberg.com)
// * License Information (https://themesberg.com/licensing)

// * Designed and coded by https://themesberg.com

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. Please contact us to request a removal.


import React from 'react'; 
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";

import ReactGA from "react-ga4";

	
// core styles
import "./scss/volt.scss";

// vendor styles
import "leaflet/dist/leaflet.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import "react-datetime/css/react-datetime.css";

import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";
import { AuthProvider } from "./pages/access/useAuth"; 

ReactGA.initialize( (window.location.hostname.indexOf('ficuseducation.com') !== -1 ) ? 'G-J6MP0Q59Z6' : 'G-BBDC0GL9B9');

ReactDOM.render( 	
			<AuthProvider >
			  <BrowserRouter basename="/">
				    <ScrollToTop />
				    <HomePage value={true} /> 
			  </BrowserRouter>
			</AuthProvider>
	  , document.getElementById("root")

);
