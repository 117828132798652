import React, { useState } from "react";
import ReactDOMServer from "react-dom/server";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Link } from "react-router-dom";
import { StarIcon, DotsHorizontalIcon, ArchiveIcon, ChevronDownIcon, ClockIcon, MailIcon, MailOpenIcon, PencilAltIcon, TrashIcon } from "@heroicons/react/solid";
import { Card, Form, Col, Button, Dropdown, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { myRoutes } from "routes";
import NOTIFICATION_DATA from "data/notificationsdetail";

const ArchiveIconHtml = ReactDOMServer.renderToString(
  <ArchiveIcon className="h-50 w-auto" />
);

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary me-3',
    cancelButton: 'btn btn-gray'
  },
  buttonsStyling: false
}));

export default () => {
  const [messages, setMessages] = useState(NOTIFICATION_DATA);
  const selectedMessageIds = messages.filter(m => m.isSelected).map(m => m.id);
  const disableMenu = selectedMessageIds.length === 0;

  const selectMessage = (id) => {
    const newMessages = messages.map(m => {
      const isSelected = m.id === id ? !m.isSelected : m.isSelected;
      return { ...m, isSelected };
    });

    setMessages(newMessages);
  };

  const toggleFavorite = (id) => {
    setMessages(messages.map(m => m.id === id ? { ...m, favorite: !m.favorite } : m));
  };

  const toggleReadStatus = (id) => {
    setMessages(messages.map(m => m.id === id ? { ...m, read: !m.read } : m));
  };

  const markSelectedMessagesAsUnread = () => {
    const newMessages = messages.map(m => selectedMessageIds.includes(m.id) ? { ...m, read: false } : m);
    setMessages(newMessages);
  };

  const markSelectedMessagesAsRead = () => {
    const newMessages = messages.map(m => selectedMessageIds.includes(m.id) ? { ...m, read: true } : m);
    setMessages(newMessages);
  };

  const deleteMessages = async (ids) => {
    const messagesNr = ids.length;
    const textMessage = messagesNr === 1
      ? "Are you sure do you want to delete this message?"
      : `Are you sure do you want to delete these ${messagesNr} messages?`;

    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "Confirm deletion",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel"
    });

    if (result.isConfirmed) {
      const newMessages = messages.filter(f => !ids.includes(f.id));
      const confirmMessage = messagesNr === 1 ? "The message has been deleted." : "The messages have been deleted.";

      setMessages(newMessages);
      await SwalWithBootstrapButtons.fire('Deleted', confirmMessage, 'success');
    }
  };

  const archiveMessages = async (ids) => {
    const messagesNr = ids.length;
    const textMessage = messagesNr === 1
      ? "Are you sure do you want to archive this message?"
      : `Are you sure do you want to archive these ${messagesNr} messages?`;

    const result = await SwalWithBootstrapButtons.fire({
      icon: "question",
      iconHtml: ArchiveIconHtml,
      title: "Confirm archivation",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel"
    });

    if (result.isConfirmed) {
      const newMessages = messages.filter(f => !ids.includes(f.id));
      const confirmMessage = messagesNr === 1 ? "The message has been archived." : "The messages were archived.";

      setMessages(newMessages);
      await SwalWithBootstrapButtons.fire('Archived', confirmMessage, 'success'); 
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center pb-2 pt-4 pb-md-4">
      	<h4>Notifications</h4>

        <div>
          <Button as={Link} to={myRoutes.SingleMessage.path} variant="secondary" className="d-inline-flex align-items-center mb-3 mb-md-0">
            <PencilAltIcon className="icon icon-xs me-2" />
            Compose
          </Button>
        </div>

        <div className="d-block d-sm-flex">
          <ButtonGroup className="mb-3 me-3 mb-md-0 d-none d-md-inline-flex">
            <OverlayTrigger placement="bottom" overlay={<Tooltip className="m-0">Mark as Unread</Tooltip>}>
              <Button variant="gray-800" disabled={disableMenu} onClick={markSelectedMessagesAsUnread} >
                <MailIcon className="icon icon-xs text-white" />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger placement="bottom" overlay={<Tooltip className="m-0">Archive</Tooltip>}>
              <Button variant="gray-800" disabled={disableMenu} onClick={() => archiveMessages(selectedMessageIds)}>
                <ArchiveIcon className="icon icon-xs text-white" />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger placement="bottom" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
              <Button variant="gray-800" disabled={disableMenu} onClick={() => deleteMessages(selectedMessageIds)}>
                <TrashIcon className="icon icon-xs text-white" />
              </Button>
            </OverlayTrigger>
          </ButtonGroup>

	        <Dropdown className="mb-3 mb-md-0">
            <Dropdown.Toggle as={Button} variant="gray-800" className="d-inline-flex alignpitems-center arrow-none" disabled={disableMenu}>
              More <ChevronDownIcon className="icon icon-xs ms-1" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
              <Dropdown.Item className="d-flex align-items-center" onClick={() => archiveMessages(selectedMessageIds)}>
                <ArchiveIcon className="icon icon-xs text-gray-400 me-2" />
                Archive
              </Dropdown.Item>
              <Dropdown.Item className="d-flex align-items-center" onClick={markSelectedMessagesAsRead}>
                <MailOpenIcon className="icon icon-xs text-gray-400 me-2" />
                Mark as read
              </Dropdown.Item>
              <Dropdown.Item className="d-flex align-items-center">
                <ClockIcon className="icon icon-xs text-gray-400 me-2" />
                Snooze
              </Dropdown.Item>

              <Dropdown.Divider as="div" className="my-1" />

              <Dropdown.Item className="d-flex align-items-center" onClick={() => deleteMessages(selectedMessageIds)}>
                <TrashIcon className="icon icon-xs text-danger me-2" />
                Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

      </div>

      <div className="message-wrapper border-0 bg-white shadow rounded mb-4">
      	{
      		(messages.length > 0) ?
		        (messages.map(m => (
		          <NotificationCardWidget
		            {...m}
		            key={`message-${m.id}`}
		            selectMessage={selectMessage}
		            toggleFavorite={toggleFavorite}
		            toggleReadStatus={toggleReadStatus}
		            archiveMessage={id => archiveMessages([id])}
		            deleteMessage={id => deleteMessages([id])}
		          />
		        ))
		        )
		        :
		        (
		        	<h6 className="text-center py-4">There are no current notifications</h6>
		        )
		    }
{/*
        <Row className="p-4">
          <Col xs={7} className="mt-1">
            Showing 1 - {messages.length}
          </Col>
          <Col xs={5}>
            <ButtonGroup className="float-end">
              <Button variant="gray-100" size="lg">
                <ChevronLeftIcon className="icon icon-xs" />
              </Button>
              <Button variant="gray-800" size="lg">
                <ChevronRightIcon className="icon icon-xs" />
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
*/}
      </div>
    </>
  );
};

export const NotificationCardWidget = (props) => {
  const { id, read, favorite, sender, timeSent, message, isSelected } = props;
  const timeColor = read ? "text-muted" : "text-danger";
  const textColor = read ? "fw-normal" : "fw-bold";
  const readText = read ? "Mark as Unread" : "Mark as Read";
  const favoriteClass = favorite ? "selected" : "";
  const MessageIcon = read ? MailIcon : MailOpenIcon;

  const toggleReadStatus = () => {
    props.toggleReadStatus && props.toggleReadStatus(id);
  };

  const toggleFavorite = () => {
    props.toggleFavorite && props.toggleFavorite(id);
  };

  const deleteMessage = () => {
    props.deleteMessage && props.deleteMessage(id);
  };

  const archiveMessage = () => {
    props.archiveMessage && props.archiveMessage(id);
  };

  const selectMessage = () => {
    props.selectMessage && props.selectMessage(id);
  };

  return (
    <Card border="bottom" className="hover-state rounded-0 rounded-top py-3">
      <Card.Body className="d-flex align-items-center flex-wrap flex-lg-nowrap py-0">
        <Col xs={1} className="align-items-center px-0 d-none d-lg-flex">
          <Form.Check type="checkbox" className="form-check inbox-check me-2 mb-0">
            <Form.Check.Input
              id={`message-${id}`}
              value={isSelected}
              onChange={selectMessage}
            />
          </Form.Check>
          <StarIcon
            className={`icon icon-sm rating-star ${favoriteClass}`}
            onClick={toggleFavorite}
          />
        </Col>
        <Col xs={10} lg={2} className="ps-0 ps-lg-3 pe-lg-3">
          <Card.Link as={Link} to={myRoutes.SingleMessage.path} className="d-flex align-items-center">
            <h6 className={`${textColor} mb-0`}>
              {sender}
            </h6>
          </Card.Link>
        </Col>
        <Col xs={2} lg={2} className="d-flex align-items-center justify-content-end px-0 order-lg-4">
          <div className={`${timeColor} small d-none d-lg-block`}>
            {timeSent}
          </div>
          <Dropdown className="ms-3">
            <Dropdown.Toggle as={Button} size="sm" variant="link" className="fs-6 px-1 py-0">
              <DotsHorizontalIcon className="icon icon-xs" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-right">
              <Dropdown.Item as={Button} variant="link" className="ms-0" onClick={archiveMessage}>
                <ArchiveIcon className="dropdown-icon text-gray-400 me-2" /> Archive
              </Dropdown.Item>
              <Dropdown.Item as={Button} variant="link" className="ms-0" onClick={toggleReadStatus}>
                <MessageIcon className="dropdown-icon text-gray-400 me-2" /> {readText}
              </Dropdown.Item>
              <Dropdown.Item as={Button} variant="link" className="ms-0">
                <ClockIcon className="dropdown-icon text-gray-400 me-2" /> Snooze
              </Dropdown.Item>

              <Dropdown.Divider as="div" className="my-1" />

              <Dropdown.Item as={Button} variant="link" className="text-danger ms-0" onClick={deleteMessage}>
                <TrashIcon className="dropdown-icon text-danger me-2" /> Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col xs={12} lg={7} className="d-flex align-items-center mt-3 mt-lg-0 ps-0">
          <Card.Link as={Link} to={myRoutes.SingleMessage.path} className="fw-normal text-gray truncate-text">
            <span className={`${textColor} ps-lg-3`}>
              {message}
            </span>
          </Card.Link>
        </Col>
      </Card.Body>
    </Card>
  );
};
