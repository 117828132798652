import React, { useState, useEffect } from "react";
import axios from "axios";
import { DocumentDuplicateIcon } from "@heroicons/react/solid";
import { Container, Col, Row, Card, Image, Button } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { getBlobFromImageElement, copyBlobToClipboard } from 'copy-image-clipboard'

import { useAuth } from "pages/access/useAuth"; 

const FicusQRCodeCard = (props) => { 

	const { locationid } = props;
	const [qrCodeURL, setQRCodeURL] = useState();
	const [linkURL, setLinkURL] = useState();
	
	useEffect(() => {
		if (locationid) {
			setLinkURL(`https://portal.ficuseducation.com/inquiry/${locationid}`);			
			setQRCodeURL(`https://portal.ficuseducation.com/inquiry/${locationid}`) 		
/*
			fetch(`https://quickchart.io/qr?text=${qrCodeURL}`)
					.then((response) => {
						setQRCodeBlob(response.body);	
					});
			return () => {
    			abortController.abort()   
    	}
*/
		} else {
			setQRCodeURL(null)			
		}
	},[locationid]);


	async function copyQRCode() {
		const imageElement = document.getElementById('qrcodeimage');
		getBlobFromImageElement(imageElement)
		  .then((blob) => {
		    return copyBlobToClipboard(blob)
		  })
		  .then(() => {
		    alert('QR Code Image Copied')
		  })
		  .catch((e) => {
		    alert('Error: ', e.message)
		  })
	}


	
	if (!locationid) {
		return (
	    <Card  className="">
	      <Card.Body className="text-center">
      		<h6>There was no LocationID specified or it was not valid.</h6>
	      </Card.Body>
	    </Card>			

		)
	} else {
		return (
	    <Card  className="">
	    	<Card.Body>
	        <input value={qrCodeURL} className="mb-1"   style={{fontSize: '0.7rem', width: '100%'}} disabled />					
	        <Image crossorigin="anonymous" id="qrcodeimage" src={`https://quickchart.io/qr?text=${qrCodeURL}`} />
	        <div className="text-center small mt-n2">
	          <Button bsPrefix="text" href="" variant="gray-700" style={{fontSize:"0.9rem"}}>Copy QR Code 
	          	<DocumentDuplicateIcon className="icon icon-xs ms-1" color="rgba(115,164,11,100%)" 
	          		onClick={() => copyQRCode()} />
	          </Button>	          

	        </div>
	      	<hr/>
	     	 <Button href={linkURL} size="sm"  className="ficusgreen px-4 py-1 my-2" target="_blank">Go to Inquiry Form...</Button>
	      </Card.Body>
	    </Card>			
			)
	}

};

export default () => {
	let {id} = useParams()
	const { 
		endpoint
	} = useAuth(); 	

	const [location, setLocation] = useState(null);

	useEffect(() => {
	  	axios.get(endpoint+"/location/default.asp", {
			  		params: { 'id': id }	
		  })  
		  .then(response => {
		  	const theResult = response.data;
		  	console.log(theResult);
		  	setLocation(response.data.length ? theResult[0] : null );
		  })
	  	.catch(error => console.log(error));
	},);



  return (
    <main>
      <section className="d-flex align-items-center vh-lg-100 mt-lg-0 bg-soft">
        <Container>
					<Row className="justify-content-center mt-2 ">
						<Col xs={12} sm={8} md={6}  className="justify-content-center ">
							<h5 className="ms-4 mt-2 text-center">Location URL and QRCode</h5>
						</Col>
					</Row>
					<Row className="justify-content-center mb-3">
						<Col xs={8} sm={8} md={6}  className="text-start small">
							<div><b>Location Name:</b>  {location && location.DisplayLocationName} </div>
							<div><b>City, State:</b>  {(location && location.City)+', '+(location && location.State)} </div>
							<div><b>Location Number:</b>  {location && location.DisplayLocationNum} </div>
							<div><b>Operator LLC:</b>  {location && location.CompanyName} </div>
							<div><b>Operator Name:</b>  {(location && location.FirstName)+' '+(location && location.LastName)} </div>
						</Col>
					</Row>
					
					<Row className="justify-content-center">
						<Col xs={12} sm={8} md={6}  className="text-center">
							<FicusQRCodeCard locationid={id} />
						</Col>
					</Row>
        </Container>
      </section>
    </main>

  );
};
