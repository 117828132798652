import React, { useState } from "react";
import { CheckCircleIcon, DocumentTextIcon} from "@heroicons/react/outline";
import { StudentTable } from "components/FicusTables.js"; 
import { EmploymentVerificationModal } from "components/FicusStatusModals.js"; 
import { StudentProcessModal } from "components/FicusModals.js";

export default () => { 

	const [showVerificationModal, setShowVerificationModal] = useState(false);
	const [showProcessModal, setShowProcessModal] = useState(false);
	
	return ( 
    <>
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-2">
        <div className="d-block mb-xl-0 w-100">
          <h5 className="mb-0">Team Member Listing </h5>
          <div>
			      <div className="btn btn-default text-start p-0" onClick={() => {setShowProcessModal(true)}} style={{fontSize:'0.7rem', color:'#0059B3'}}>
			      	Process Guide <DocumentTextIcon className="icon icon-xxs" style={{strokeWidth:"2", verticalAlign:'text-bottom'}}/></div>
	    			<div className="btn btn-default float-end p-0" onClick={() => {setShowVerificationModal(true)}} style={{fontSize:'0.7rem', color:'#0059B3'}}>
	    					Employment Verification <CheckCircleIcon className="icon icon-xxs" style={{ strokeWidth:"2", verticalAlign:'text-bottom'}}></CheckCircleIcon>
						</div>
					</div>
        </div>
      </div>		
      <StudentTable sortorder={'status'} ficusstatus={''} showphase={true} />
			<EmploymentVerificationModal 
				showme={showVerificationModal} 
//				studentlist={studentList} 
//				setstudentlist={setStudentList} 
//				currentstatus={selectedStatus} 
				onClose={() => setShowVerificationModal(false)} />
      <StudentProcessModal show={showProcessModal} onHide={() => {setShowProcessModal(false)}}/>
    </> 
  );
};
