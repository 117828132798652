
import React from "react";
import { HomeIcon } from "@heroicons/react/solid";
import { Breadcrumb } from 'react-bootstrap';

import { HubspotContacts } from "../components/FicusTables.js";
 

export default () => { 
  return (
    <>
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-xl-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
            <Breadcrumb.Item>Tables</Breadcrumb.Item>
            <Breadcrumb.Item active>HubSpot Contacts</Breadcrumb.Item>
          </Breadcrumb>

          <h4>HubSpot Contacts </h4>
          <p className="mb-0">
            Axios API using data at https://api.hubspot.com/crm/v3/objects/contacts.
          </p>
          <p>
            <i>Tested on local environment with CORS disabled on browser!  Will fail due to CORS until proper proxy mechanism is in place.</i>
          </p>
        </div>
      </div>

      <HubspotContacts /> 
    </> 
  );
};
