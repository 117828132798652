import axios from "axios";
import React, { useState, useEffect } from 'react';
import ApexChart from "react-apexcharts";
import { PieChartWidget } from "../components/Widgets";

export const MIQDestinationChart = () => {
	const [miqDestinations, miqSetDestinations] = useState([]);
	
	useEffect(() => {
	  axios
	  .get("https://broker.missionsiq.com/ajax/topten_countries.asp")
	  .then(response => {
	  	const theResult = response.data;
	  	console.log(theResult);
	  	miqSetDestinations(theResult);
	  })
	  .catch(error => console.log(error));	
	}, []);
	

  return (
    <PieChartWidget
      title={"Top 10 Destination Countries"}
      data={miqDestinations} />
  );
};

export const MIQTopCountries = () => {
	const [miqTopCountries, setmiqTopCountries] = useState([]);
	
	useEffect(() => {
	  axios
	  .get("https://broker.missionsiq.com/ajax/topten_countries.asp")
	  .then(response => {
	  	const theResult = response.data;
	  	console.log(theResult);
	  	setmiqTopCountries(theResult);
	  })
	  .catch(error => console.log(error));	
	}, []);
	
  const chartSeries = Array.from(miqTopCountries, function(m) { return m.value });
  const chartLabels = Array.from(miqTopCountries, function(m) { return m.label });

	
		const seriesPieChart = chartSeries;
		const optionsPieChart = {
			
	    plotOptions: {
	      pie: {
	        donut: {
	          size: '45%'
	        }
	      }
	    },
		  theme: {
		    mode:'light',
		    palette: 'palette7',
		    monochrome: {
		    	enabled: false,
          color: '#255aee',
          shadeTo: 'light',
          shadeIntensity: 0
      	}
		  },
		  dataLabels: {
		  	dropShadow: {
		    	enabled: false
		    },
		    style: { 
		    	fontSize: '14px',
		    	colors: ['#eee']
		    }
		  },

/*
		  theme: {
		    monochrome: {
		      enabled: true,
		      color: '#31316A',
		    }
		  },
*/
		  labels: chartLabels,
		  responsive: [{
		    breakpoint: 480,
		    options: {
		      chart: {
		        width: 200
		      },
		      legend: {
		        position: 'bottom'
		      }
		    }
		  }],
		  tooltip: {
		    fillSeriesColor: false,
		    onDatasetHover: {
		      highlightDataSeries: false,
		    },
		    theme: 'light',
		    style: {
		      fontSize: '16px',
		      fontFamily: 'Inter',
		    },
		    y: {
		      formatter: function (val) {
		        return "$ " + val + "k"
		      }
		    },
		  },
		};


		return (
		  <ApexChart
		    type="donut"
		    height={360}
		    series={seriesPieChart}
		    options={optionsPieChart}
		  />

		);
	};



export const MIQDestinationX = () => {
	
		const seriesPieChart = [44, 55, 13, 43, 22];
		const optionsPieChart = {
		  theme: {
		    monochrome: {
		      enabled: true,
		      color: '#31316A',
		    }
		  },
		  labels: ['United States', 'Canada', 'United Kingdom', 'Germany', 'France'],
		  responsive: [{
		    breakpoint: 480,
		    options: {
		      chart: {
		        width: 200
		      },
		      legend: {
		        position: 'bottom'
		      }
		    }
		  }],
		  tooltip: {
		    fillSeriesColor: false,
		    onDatasetHover: {
		      highlightDataSeries: false,
		    },
		    theme: 'light',
		    style: {
		      fontSize: '12px',
		      fontFamily: 'Inter',
		    },
		    y: {
		      formatter: function (val) {
		        return "$ " + val + "k"
		      }
		    }
		  },
		};


		return (
		  <ApexChart
		    type="pie"
		    height={360}
		    series={seriesPieChart}
		    options={optionsPieChart}
		  />

		);
	};