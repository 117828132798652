import React from "react";
import FicusGroupAsset from "assets/img/logos/ficus_group.png";
//import FicusGroupSmallAsset from "assets/img/logos/ficus_group_small.png";
import FicusTreeAsset from "assets/img/logos/ficus_group_small.png";
import FicusTreeSmallAsset from "assets/img/logos/ficus_group_small.png";

import FicusGroupSmallAsset from "assets/img/logos/FicusEducation_Logo_Secondary_CMYK.png";

export const FicusLogo = FicusGroupAsset;
export const FicusLogoSmall = FicusGroupSmallAsset;
export const FicusTree = FicusTreeAsset;
export const FicusTreeSmall = FicusTreeSmallAsset;

export default () => {
 return(
 <>
 </>
 )
}
