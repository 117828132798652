
import React from "react";
import moment from "moment-timezone";
import { Row, Col, Card, Container } from 'react-bootstrap';

import { FicusLogoSmall } from "assets/FicusInfo.js";

export default (props) => {
  const currentYear = moment().get("year");

  return (
    <Container className="d-block pt-0 ps-0 pe-2 pb-0">
      <footer className="  p-2 mb-2 mt-3">
      	<div className=" row" style={{maxWidth:'700px', margin:"0 auto"}}>
        <Row className="justify-content-center">
          <Col xs={8} sm={6} className="mt-2">
		          <a href="https://ficuseducation.com" rel="noopener noreferrer" target="_blank">
		          	<img src={FicusLogoSmall} alt="Ficus Education" title="Ficus Education WebSite" fluid="true"/> 
		          </a>
          </Col>
{/*          <Col xs ={{ span: 4, offset: 2  }}  className="text-left text-lg-start">
            <ul className="list-inline list-group-flush list-group-borderless text-end mb-0 vertical-tab">
              <li className="list-item ps-1 ps-xs-2">
					      <Nav.Link as={NavLink} to="/documentation/about" className="text-primary fw-normal">
					      	About Us
					      </Nav.Link>
              </li>
              <li className="list-item ps-1 ps-xs-2">
					      <Nav.Link as={NavLink} to="/documentation/about" className="text-primary fw-normal">
					      	Contact Us
					      </Nav.Link>
              </li>
              <li className="list-item ps-1 ps-xs-2">
					      <Nav.Link as={NavLink} to="/documentation/about" className="text-primary fw-normal" style={{fontSize:'0.7rem'}}>
					      	888-344-2053
					      </Nav.Link>
              </li>

              <li className="list-item ps-1 ps-xs-2">
					      <Nav.Link as={NavLink} to="/documentation/about" className="text-primary fw-normal" style={{fontSize:'0.7rem'}}>
					      	<div>11175 Cicero Drive, Suite 500</div>
					      	<div>Alpharetta, GA 30022</div>
					      </Nav.Link>
              </li>

            </ul>
          </Col>
*/}
        </Row>
        <Row>
          <Col xs={12} className="text-center">
            <Card.Link href="https://ficuseducation.com"  rel="noopener noreferrer" target="_blank" style={{fontSize:'0.5rem'}}>
              © {`${currentYear} `} Ficus Group, LLC All Rights Reserved
            </Card.Link>
          </Col>
        </Row>
        </div>
      </footer>
    </Container>
  );
};
