
import React from 'react';
import { Row, Col, Card, Container } from 'react-bootstrap';



export default () => (
  <Container className="px-0">
    <Row>
      <Col xs={12} className="p-3" style={{fontSize:"1.0rem"}}>
              <h3 id="overview">About the Ficus Group </h3>

              	<p style={{fontSize:"0.9rem", lineHeight:"1rem"}}>The Ficus Group, LLC was founded to meet a growing administration, technology and billing need of a 
              	large group of Chick-fil-A independent Owner/Operators across the US.</p>

								<p style={{fontSize:"0.9rem", lineHeight:"1rem"}}>Leadership from the same organization that supports thousands of employers and millions of 
								employees with benefits administration was called upon to assemble a WORLD CLASS organization to 
								improve the way employers support growing the skills of their work force.</p>

								<p style={{fontSize:"0.9rem", lineHeight:"1rem"}}>Milestone: In March of 2023, Ficus Group surpassed over 350 Employers and nearly 2,000 employees/students
							 	as they started their debt-free pathway to earning a college degree.</p>
							 
              <p style={{fontSize:"0.9rem", lineHeight:"1rem"}}>Learn more at the <Card.Link href="https://ficuseducation.com/about-us" target="_blank" style={{color:"blue", textDecoration:"underline" }}>Ficus Group Site</Card.Link></p>

              <h6 id="getting-support">Have questions?</h6>
              <p style={{fontSize:"0.9rem", lineHeight:"1rem"}}>We are here for you! Please <Card.Link href="/Contact" style={{color:"blue", textDecoration:"underline" }}>contact us</Card.Link> and we&rsquo;ll get back to you in no time!</p>

      </Col>
    </Row>
  </Container>
);
