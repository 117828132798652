
import React, { useState } from "react";
import { Helmet } from 'react-helmet';

import { LockClosedIcon, MailIcon } from "@heroicons/react/outline";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FicusSignInForm } from 'pages/access/FicusAdminSignin.js';

import { myRoutes } from "routes";

import { useAuth } from "pages/access/useAuth"; 

// JKG Ficus Specific Starts Here
import FicusLogo from "assets/img/logos/FicusEducation_Logo_Secondary_CMYK.png";
// JKG - Make dynamic, driven from organizationID passed in by URL

const FicusHelpPhone = "888-344-2053"
const OrganizationName = "5000 Futures"
const OrganizationURL = "5000Futures.org"


export default () => {
		const auth = useAuth();


	  return (
	    <main>
	      <section className="d-flex align-items-center vh-lg-100 mt-2 mt-lg-0 bg-soft">
	        <Container>
	          <Row className="justify-content-center">
	            <Col xs={12} className="d-flex align-items-center justify-content-center"> 
	              <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
	                <div className="text-center text-md-center mt-n4">
					          <img src={FicusLogo} alt="Ficus Education" title="Ficus Education Partner Access" /> 
	                </div>
	                <div className="text-center text-md-center mb-4 mt-n4">
	                	<h4 style={{color:'#68a543', fontWeight:'bold'}}>Partner Access to Ficus Operator Dashboards</h4>
		              </div>
	                <FicusSignInForm persontype='partner' />

							    <div className="d-flex justify-content-center align-items-top mt-2 mb-4">
							      <Card.Link as={Link} to={myRoutes.ResetPassword.path} className="small text-end">Sign in problems? Click here!</Card.Link>
							    </div>
	              </div>
	            </Col>
	          </Row>
	        </Container>
	      </section>
	    </main>
	  );
}



