import React, { useState, useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {  DotsHorizontalIcon, ArchiveIcon, ClockIcon, MailIcon, MailOpenIcon, TrashIcon } from "@heroicons/react/solid";
import { Card, Form, Row, Col, Button, Dropdown, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAuth } from "pages/access/useAuth"; 

const ArchiveIconHtml = ReactDOMServer.renderToString(
  <ArchiveIcon className="h-50 w-auto" />
);

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary me-3',
    cancelButton: 'btn btn-gray'
  },
  buttonsStyling: false
}));

export default () => {
	const { 
		user, 
		endpoint
	} = useAuth(); 	

  const [notifications, setNotifications] = useState([]);
  const selectedNotificationIds = notifications.filter(m => m.isSelected).map(m => m.id);
  const disableMenu = selectedNotificationIds.length === 0;

	useEffect(() => {
	  axios.get(endpoint+"/notifications/", {
  		params: { 
  			'uid': user.uid 
  			}	
		  })  
		  .then(response => {
		  	const theResult = response.data;
		  	console.log(theResult);
		  	setNotifications(theResult);
		  })
	  	.catch(error => console.log(error));	
	},[user, endpoint]);
	
	
  const selectNotification = (id) => {
    const newNotifications = notifications.map(m => {
      const isSelected = m.id === id ? !m.isSelected : m.isSelected;
      return { ...m, isSelected };
    });

    setNotifications(newNotifications);
  };

  const toggleReadStatus = (id) => {
    setNotifications(notifications.map(m => m.id === id ? { ...m, read: !m.read } : m));
  };

  const markSelectedNotificationsAsUnread = () => {
    const newNotifications = notifications.map(m => selectedNotificationIds.includes(m.id) ? { ...m, read: false } : m);
    setNotifications(newNotifications);
  };

/*
  const markSelectedNotificationsAsRead = () => {
    const newNotifications = notifications.map(m => selectedNotificationIds.includes(m.id) ? { ...m, read: true } : m);
    setNotifications(newNotifications);
  };
*/

  const deleteNotifications = async (ids) => {
    const notificationsNr = ids.length;
    const textMessage = notificationsNr === 1
      ? "Are you sure do you want to delete this message?"
      : `Are you sure do you want to delete these ${notificationsNr} notifications?`;

    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "Confirm deletion",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel"
    });

    if (result.isConfirmed) {
      const newNotifications = notifications.filter(f => !ids.includes(f.id));
      const confirmNotification = notificationsNr === 1 ? "The notification has been deleted." : "The notifications have been deleted.";

      setNotifications(newNotifications);
      await SwalWithBootstrapButtons.fire('Deleted', confirmNotification, 'success');
    }
  };

  const archiveNotifications = async (ids) => {
    const notificationsNr = ids.length;
    const textMessage = notificationsNr === 1
      ? "Are you sure do you want to archive this notification?"
      : `Are you sure do you want to archive these ${notificationsNr} notifications?`;

    const result = await SwalWithBootstrapButtons.fire({
      icon: "question",
      iconHtml: ArchiveIconHtml,
      title: "Confirm archivation",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel"
    });

    if (result.isConfirmed) {
      const newNotifications = notifications.filter(f => !ids.includes(f.id));
      const confirmNotification = notificationsNr === 1 ? "The notification has been archived." : "The notifications were archived.";

      setNotifications(newNotifications);
      await SwalWithBootstrapButtons.fire('Archived', confirmNotification, 'success'); 
    }
  };

  return (
    <>
      <div className="justify-content-between align-items-center pb-2 pt-4 pb-md-4">
      			<h4>Notifications</h4>
        <div className="d-block d-sm-flex">

{/*
	}	        <Dropdown className="mb-3 mb-md-0">
            <Dropdown.Toggle as={Button} variant="gray-800" className="d-inline-flex alignpitems-center arrow-none" disabled={disableMenu}>
              More <ChevronDownIcon className="icon icon-xs ms-1" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
              <Dropdown.Item className="d-flex align-items-center" onClick={() => archiveNotifications(selectedNotificationIds)}>
                <ArchiveIcon className="icon icon-xs text-gray-400 me-2" />
                Archive
              </Dropdown.Item>
              <Dropdown.Item className="d-flex align-items-center" onClick={markSelectedNotificationsAsRead}>
                <MailOpenIcon className="icon icon-xs text-gray-400 me-2" />
                Mark as read
              </Dropdown.Item>
              <Dropdown.Item className="d-flex align-items-center">
                <ClockIcon className="icon icon-xs text-gray-400 me-2" />
                Snooze
              </Dropdown.Item>

              <Dropdown.Divider as="div" className="my-1" />

              <Dropdown.Item className="d-flex align-items-center" onClick={() => deleteNotifications(selectedNotificationIds)}>
                <TrashIcon className="icon icon-xs text-danger me-2" />
                Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
*/}
        </div>

      </div>

      <Card className="message-wrapper mb-4">
	      <Card.Body className="pt-1 pe-1">

	        <ButtonGroup className="mb-3 me-1 mt-1 mb-md-0 d-none d-md-inline-flex float-end">
	          <OverlayTrigger placement="bottom" overlay={<Tooltip className="m-0">Mark as Unread</Tooltip>}>
	            <Button variant="gray-800" disabled={disableMenu} onClick={markSelectedNotificationsAsUnread} >
	              <MailIcon className="icon icon-xs text-white" />
	            </Button>
	          </OverlayTrigger>

	          <OverlayTrigger placement="bottom" overlay={<Tooltip className="m-0">Archive</Tooltip>}>
	            <Button variant="gray-800" disabled={disableMenu} onClick={() => archiveNotifications(selectedNotificationIds)}>
	              <ArchiveIcon className="icon icon-xs text-white" />
	            </Button>
	          </OverlayTrigger>

	          <OverlayTrigger placement="bottom" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
	            <Button variant="gray-800" disabled={disableMenu} onClick={() => deleteNotifications(selectedNotificationIds)}>
	              <TrashIcon className="icon icon-xs text-white" />
	            </Button>
	          </OverlayTrigger>
	        </ButtonGroup>
	      	{
	      		(notifications.length > 0) ?
			        (notifications.map(m => (
			          <NotificationCardWidget
			            {...m}
			            key={`notification-${m.id}`}
			            selectNotification={selectNotification}
			            toggleReadStatus={toggleReadStatus}
			            archiveNotification={id => archiveNotifications([id])}
			            deleteNotification={id => deleteNotifications([id])}
			          />
			        ))
			        )
			        :
			        (
			        	<h6 className="text-center py-4">There are no current notifications</h6>
			        )
			    }
	{/*
	        <Row className="p-4">
	          <Col xs={7} className="mt-1">
	            Showing 1 - {notifications.length}
	          </Col>
	          <Col xs={5}>
	            <ButtonGroup className="float-end">
	              <Button variant="gray-100" size="lg">
	                <ChevronLeftIcon className="icon icon-xs" />
	              </Button>
	              <Button variant="gray-800" size="lg">
	                <ChevronRightIcon className="icon icon-xs" />
	              </Button>
	            </ButtonGroup>
	          </Col>
	        </Row>
*/}
      	</Card.Body>
      </Card>
    </>
  );
};

export const NotificationCardWidget = (props) => {
  const { id, status, sendername, created, subject, body, isSelected } = props;
  const timeColor = (status === 'read') ? "text-muted" : "text-danger";
  const textColor = (status === 'read') ? "fw-normal" : "fw-bold";
  const readText = (status === 'read') ? "Mark as Unread" : "Mark as Read";
  const NotificationIcon = (status === 'read') ? MailIcon : MailOpenIcon;

  const toggleReadStatus = () => {
    props.toggleReadStatus && props.toggleReadStatus(id);
  };

  const deleteNotification = () => {
    props.deleteNotification && props.deleteNotification(id);
  };

  const archiveNotification = () => {
    props.archiveNotification && props.archiveNotification(id);
  };

  const selectNotification = () => {
    props.selectNotification && props.selectNotification(id);
  };

  return (
    <Card border="bottom" className="hover-state rounded-0 rounded-top py-3">
      <Card.Body className="d-flex align-items-center flex-wrap flex-lg-nowrap py-0">
        <Col xs={1} className="align-items-center px-0 d-none d-lg-flex">
          <Form.Check type="checkbox" className="form-check inbox-check me-2 mb-0">
            <Form.Check.Input
              id={`notification-${id}`}
              value={isSelected}
              onChange={selectNotification}
            />
          </Form.Check>
        </Col>
        <Col xs={10} lg={2} className="ps-0 ps-lg-3 pe-lg-3">
          <h6 className={`${textColor} mb-0`}>
            {sendername}
          </h6>
        </Col>
        <Col xs={2} lg={2} className="d-flex align-items-center justify-content-end px-0 order-lg-4">
          <div className={`${timeColor} small d-none d-lg-block`}>
            {created}
          </div>
          <Dropdown className="ms-3">
            <Dropdown.Toggle as={Button} size="sm" variant="link" className="fs-6 px-1 py-0">
              <DotsHorizontalIcon className="icon icon-xs" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-right">
              <Dropdown.Item as={Button} variant="link" className="ms-0" onClick={archiveNotification}>
                <ArchiveIcon className="dropdown-icon text-gray-400 me-2" /> Archive
              </Dropdown.Item>
              <Dropdown.Item as={Button} variant="link" className="ms-0" onClick={toggleReadStatus}>
                <NotificationIcon className="dropdown-icon text-gray-400 me-2" /> {readText}
              </Dropdown.Item>
              <Dropdown.Item as={Button} variant="link" className="ms-0">
                <ClockIcon className="dropdown-icon text-gray-400 me-2" /> Snooze
              </Dropdown.Item>

              <Dropdown.Divider as="div" className="my-1" />

              <Dropdown.Item as={Button} variant="link" className="text-danger ms-0" onClick={deleteNotification}>
                <TrashIcon className="dropdown-icon text-danger me-2" /> Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col xs={12} lg={7} className="d-flex align-items-center mt-3 mt-lg-0 ps-0">
            <span className={`${textColor} ps-lg-3`}>
              {subject}<br/>
              {body}
            </span>
        </Col>
      </Card.Body>
    </Card>
  );
};
