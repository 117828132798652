
import React from "react";
import axios from "axios";
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Card, Button, Container, InputGroup } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FicusLogo from "assets/img/logos/ficus_group.png";

import { useAuth } from "pages/access/useAuth"; 

export default () => {
	const { 
		secToken,
		endpoint
	} = useAuth(); 	

	const navigate = useNavigate();			
	const email = React.createRef(); 		 
				
	const MessageSent = withReactContent(Swal.mixin({
	  customClass: {
	    confirmButton: 'btn btn-primary me-3',
	    cancelButton: 'btn btn-gray'
	  },
	  buttonsStyling: false
	}));

  async function ShowAlert(msgicon, titlemsg, msg) { 
		await MessageSent.fire({
			icon: msgicon,
			title: titlemsg,
			text: msg,
			confirmButtonText: 'Return to Sign In Page',
  	}).then((result) => {
  		navigate('/')  		
  	});
	}

	const handleSubmit = (event) => {			
	  event.preventDefault();
		
		let form_data = new URLSearchParams({userid: email.current.value}).toString();
		
    axios.post(endpoint+'/users/signin/ajax-lost-pswd.asp', form_data, {
				params: { 
					'sectoken': secToken, 
					'pswd_reset_redirect': "/access/changepassword/"
				} 
    	})
		.then((response) => {
			if (response.data.includes('Emailed the password reset link') ) {
				ShowAlert('success','Password Link Sent', 'Your reset link has been sent to the email address provided.  If you do not recieve an email in the next 2 minutes, please check your spam folder and ensure that you are providing the correct email address.');
			}else {
				ShowAlert('error','Reset Request Error', response.data);
			}	
    })
		.catch(error => console.log(error.response.data));	

		
  };
	
	
  return (
    <main>
      <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link as={Link} to={"/"} className="d-flex align-items-center justify-content-center">
                <ArrowNarrowLeftIcon className="icon icon-xs me-2" /> Return to Sign In page
              </Card.Link>
            </p>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="signin-inner my-3 my-lg-0 bg-white shadow border-0 rounded p-4 p-lg-5 w-100 fmxw-500">
                <h3>Password Reset Required</h3>
                <p className="mb-4">Please provide the email address that you use to sign and we will send 
                a reset code to that address.</p>
                <Form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <Form.Label htmlFor="email">Your Email</Form.Label>
                    <InputGroup id="email">
                      <Form.Control required autoFocus type="email" placeholder="emailaddress@company.com" ref={email}/>
                    </InputGroup>
                  </div>
                  <div className="d-grid">
                    <Button variant="gray-800" type="submit">
                      Send My Password Reset Link
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
          <div className="text-center text-md-center mb-4 mt-4">
	          <a href="https://ficuseducation.com" rel="noopener noreferrer" target="_blank">
	          	<img src={FicusLogo} alt="Ficus Education" title="Ficus Education WebSite" style={{maxWidth: "250px"}}/> 
	          </a>
          </div>
        </Container>
      </section>
    </main>
  );
};
