
import React, { useState } from "react";
import moment from 'moment-timezone';
import axios from "axios";
import { Button, Modal, Table } from 'react-bootstrap';
import { VerificationTable } from "components/FicusTables.js";

import { useAuth } from "pages/access/useAuth"; 

export	const StudentStatusModal  = (props) => {
	const {showme, studentlist, setstudentlist, currentstatus, detailstatus, onClose } = props;
	const { 
		user,
		curLocation,
		endpoint
	} = useAuth();  
	
	const [getData, setGetData] = useState(true);

	const onShowHandler = (user, currentstatus, loc, setstudentlist) => {
/* 		 GetStudentList(user, currentstatus, loc && loc.locationid, setstudentlist );		*/
			setGetData(false)
		  axios.get(endpoint+"/student/default.asp", {
				  		params: { 
				  			'companyid':user.companyid, 
				  			'ficusstatus':currentstatus, 
				  			'detailstatus':detailstatus,
				  			'locationid': loc && loc.locationid,  
				  			'showcurrentonly': 'true'
				  		}	
			  })  
			  .then(response => {
			  	const theResult = response.data;
			  	setstudentlist(theResult);
			  })
		  	.catch(error => console.log(error));
	}
	
	function closeMe() {
		onClose();
		setGetData(true);
	}
	
	return (
  <Modal centered show={showme} onHide={() => {}} onShow ={showme && getData && onShowHandler(user, currentstatus, curLocation, setstudentlist)}>
    <Modal.Header>
      <Modal.Title>
      	<h6>{currentstatus} - {detailstatus} </h6>
      	<div style={{fontSize:'small'}}>{curLocation ? curLocation.locationname : "All Stores"}</div>
      </Modal.Title>
      <Button variant="close" aria-label="Close" onClick={closeMe} />
  	</Modal.Header>
      <Modal.Body>
      {studentlist && ShowStatusTable(studentlist)}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="info" size="sm" onClick={closeMe}>
      	Close
      </Button>
    </Modal.Footer>
  </Modal>  
  )	
};

const ShowStatusTable = (studentlist) => {

  const TableRow = (props) => {
    const { FirstName, LastName, StudentStatus, StatusReason, AssignedDate, StartDate } = props;

    return (
      <tr>
        <td className="border-0" style={{ width: 'auto' }}>{FirstName+' '+LastName}</td>
        <td className="border-0" style={{ width: 'auto' }}>{((StatusReason !== '') ? StatusReason : StudentStatus)}</td>
        <td className="border-0" style={{ width: 'auto' }}>{moment(AssignedDate).format('L')}</td>
        <td className="border-0" style={{ width: 'auto' }}>{(StartDate) ? moment(StartDate).format('L') : 'N/A'}</td>
      </tr>
    );
  };

  return (
  <>
     <Table responsive className="dashboard table-centered rounded" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
      <thead className="thead-light">
        <tr>
          <th className="border-0" style={{ width: 'auto' }}>Team Member</th>
          <th className="border-0" style={{ width: 'auto' }}>Status Detail</th>
          <th className="border-0" style={{ width: 'auto' }}>Status Changed</th>
          <th className="border-0" style={{ width: 'auto' }}>Start Classes</th>
        </tr>
      </thead>
      <tbody>
        {studentlist.map(c => <TableRow key={`persons-${c.PersonID}`} {...c} />)}        
      </tbody>
     </Table>

		</>
  );
}  


// Employee Verification Modal
export	const EmploymentVerificationModal  = (props) => {
	const {showme, onClose } = props;
	const { 
		user,
		curLocation,
		endpoint
	} = useAuth();  
	
	const [getData, setGetData] = useState(true);
	const [studentList, setStudentList] = useState([]);

	const onShowHandler = (user, loc) => {
			setGetData(false)
		  axios.get(endpoint+"/student/default.asp", {
				  		params: { 
				  			'companyid':user.companyid, 
				  			'locationid': loc && loc.locationid  
				  		}	
			  })  
			  .then(response => {
			  	const theResult = response.data;
			  	setStudentList(theResult);
			  })
		  	.catch(error => console.log(error));
	}
	
	function closeMe() {
		onClose();
		setGetData(true);
	}

	return (
  <Modal centered show={showme} onHide={() => {}} dialogClassName="verifymodal"
  		onShow ={showme && getData && onShowHandler(user, curLocation)}>
    <Modal.Header>
      <Modal.Title>
      	<h6>Team Member Employment Status </h6>
      	<div style={{fontSize:'small'}}>{curLocation ? curLocation.locationname : "All Stores"}</div>
      </Modal.Title>
      <Button variant="close" aria-label="Close" onClick={closeMe} />
  	</Modal.Header>
      <Modal.Body>
      {studentList && VerificationTable(studentList)}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="info" size="sm" onClick={closeMe}>
      	Close
      </Button>
    </Modal.Footer>
  </Modal>  
  )	



};

