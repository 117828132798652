
import React, { useState, useEffect } from "react";
import axios from "axios";
import ApexChart from "react-apexcharts";
import { StudentStatusModal } from "components/FicusStatusModals";

import { useAuth } from "pages/access/useAuth"; 

	
export const StatusPieChart = () => {
	const { 
		user,
		curLocation,
		endpoint
	} = useAuth();  

	const [showStudentStatusModal, setShowStudentStatusModal] = useState(false);
	const [studentList, setStudentList] = useState([]);
	
	const [studentStatuses, setStudentStatuses] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState(null);
	
	useEffect(() => {
	  axios.get(endpoint+"/student/stats/status/default.asp", {
			  		params: { 'companyid': user.companyid, 'locationid': curLocation && curLocation.locationid }	
		  })  
		  .then(response => {
		  	const theResult = response.data;
		  	setStudentStatuses(theResult);
		  })
	  	.catch(error => console.log(error));	
	},[curLocation, user.companyid, endpoint]);
	

	const LabelClicked = (ficusstatus) => {
		setStudentList([]);
		setSelectedStatus(ficusstatus)
		setShowStudentStatusModal(true);
	}
	
  const chartSeries = Array.from(studentStatuses, function(m) { return m.statuscount });
  const chartLabels = Array.from(studentStatuses, function(m) { return m.ficusstatus });

	const seriesPieChart = chartSeries;
	

	const OptionsPieChart = (props) => {
		
		return({
			chart: {
				id: 'studentstatus',
	      events: {
	      	dataPointSelection: function(event, chartContext, config) {
	      			if (config.selectedDataPoints[0].length !== 0) {
	      				/* setSelectedStatus(config.w.config.labels[config.selectedDataPoints[0]]);  */
	      				LabelClicked(config.w.config.labels[config.selectedDataPoints[0]]);
	      		} else {
/*	      				alert("all locations selected") */
	      		}
	      	}
	      },
			},
			grid: {
	        padding: {
	          bottom: 0
	        }
	      },

	    plotOptions: {
	      pie: {
	        startAngle: -100,
	        endAngle: 100,
	      	dataLabels: {
	      		offset: 20
	      	},
	        donut: {
	          size: '45%',
	          background: 'transparent',
	          labels: {
	          	show: true,
	          	name: {
	          		show:true,
	          	},
	          	value: {
	         			offsetY: 20,
	          	},
	          	total: {
	          		show:true,
	          		label: 'Students',
	          		fontSize: '.9rem',
			 					formatter: function (w) {
			 						let myTotal = (w.globals.seriesTotals.reduce((a, b) => {return a + b }, 0))
			            return myTotal
			          }         		
	          	}
	          }
	        }
	      }
	    },
/*
		  theme: {
		    mode:'light',
		    palette: 'palette2',
		    monochrome: {
		    	enabled: false,
	        color: '#255aee',
	        shadeTo: 'light',
	        shadeIntensity: 0
	    	}
		  },
*/
		  dataLabels: {
		  	dropShadow: {
		    	enabled: false
		    },
		    style: { 
		    	fontSize: '12px',
		    	fontWeight: 'normal',
		    	colors: ['#fff']
		    },
		    background: {
		    	enabled: true,
		    	opacity: 1.0,
		    	foreColor: '#000',
		    	borderColor: '#888',
		    	dropShadow: {
		    		enabled: true
		    	}
		    },
			  formatter: function(value, { seriesIndex, dataPointIndex, w }) {
			    	return w.config.series[seriesIndex] + " " + w.config.labels[seriesIndex]
			    /* 
			     	return w.config.series[seriesIndex]
			    	return w.config.labels[seriesIndex] 
			    */
			  }	  
		  },
		  legend: {
		  	show: false,
		  	 position: 'bottom',
		  	 fontSize: '16px'	  	
		  },

	
		  theme: {
		    monochrome: {
		      enabled: true,
		      color: '#0080C0',
		    }
		  },
	
	  labels: chartLabels,
	  responsive: [{
	    breakpoint: 425,
	    options: {
	      chart: {
	        width: '90%'
	      },
	      legend: {
	        position: 'bottom'
	      }
	    }
	  }],
		  tooltip: {
		  	enabled: false,
		    fillSeriesColor: false,
		    onDatasetHover: {
		      highlightDataSeries: false,
		    },
		    theme: 'light',
		    style: {
		      fontSize: '16px',
		      fontFamily: 'Inter',
		    },
		    y: {
		      formatter: function (val) {
		        return val + " Team Members"
		      }
		    },
		  },
		})
	};
	
	  
	return (
		<>
		<div style={{height: "150px"}}>
	  <ApexChart 
	    type="donut"
	    series={seriesPieChart}
	    options={OptionsPieChart()} 
 		 />
 		 </div>
		<StudentStatusModal 
			showme={showStudentStatusModal} 
			studentlist={studentList} 
			setstudentlist={setStudentList} 
			currentstatus={selectedStatus} 
			onClose={() => setShowStudentStatusModal(false)} />
		</>

	);			
}
// END STORY CHART 

export const StudentLocations = () => {
	const { 
		user, 
		curLocation,
		setCurLocation,
		endpoint
	} = useAuth();  
	
	const [studentLocations, setStudentLocations] = useState([]);

	useEffect(() => {
	  axios.get(endpoint+"/company/stats/locations/default.asp", {
			  		params: { 'companyid': user.companyid, 'locationid': curLocation && curLocation.locationid }	
		  })  
		  .then(response => {
		  	const theResult = response.data;
		  	setStudentLocations(theResult);
		  })
	  	.catch(error => console.log(error));	
	},[curLocation, user.companyid, endpoint]);
		
  const chartSeries = Array.from(studentLocations, function(m) { return m.studentcount });
  const chartLabels = Array.from(studentLocations, function(m) { return m.Name });

	
	const seriesPieChart = chartSeries;
	const optionsPieChart = {
		chart: {
      events: {
      	dataPointSelection: function(event, chartContext, config) {
      		if (config.selectedDataPoints[0].length !== 0) {
      			setCurLocation(user.locationList[config.dataPointIndex]);      		
      		} else {
      			setCurLocation(null);
      		}
      	}
      },		
		},
    plotOptions: {
      pie: {
      	customScale: 1,
      	dataLabels: {
      		offset: 10
      	},
        donut: {
          size: '45%',
          background: 'transparent',
          labels: {
          	show: true,
          	name: {
          		show:true,
          	},
          	value: {
         			offsetY: 5,
          	},
          	total: {
          		show:true,
          		label: 'Total',
          		fontSize: '16px',
		 					formatter: function (w) {
		            return w.globals.seriesTotals.reduce((a, b) => { return a + b }, 0) 
		          }         		
          	}
          }
        }
      }
    },
	  theme: {
	    mode:'light',
	    palette: 'palette2',
	    monochrome: {
	    	enabled: false,
        color: '#255aee',
        shadeTo: 'light',
        shadeIntensity: 0
    	}
	  },
	  dataLabels: {
	  	dropShadow: {
	    	enabled: false
	    },
	    style: { 
	    	fontSize: '14px',
	    	fontWeight: 'bold',
	    	colors: ['#fff']
	    },
	    background: {
	    	enabled: true,
	    	foreColor: '#000'
	    },
		  formatter: function(value, { seriesIndex, dataPointIndex, w }) {
		     	return w.config.series[seriesIndex] + " Students"
		    /* 
		    	return w.config.labels[seriesIndex] + ": " + w.config.series[seriesIndex] + " Students" 
		    	return w.config.labels[seriesIndex] 
		    */
		  }	  
	  },
	  legend: {
	  	 position: 'bottom',
	  	 fontSize: '16px'	  	
	  },

/*
	  theme: {
	    monochrome: {
	      enabled: true,
	      color: '#31316A',
	    }
	  },
*/
	  labels: chartLabels,
	  responsive: [{
	    breakpoint: 425,
	    options: {
	      chart: {
	        width: '150%'
	      },
	      legend: {
	        position: 'bottom'
	      }
	    }
	  }],
	  tooltip: {
	    fillSeriesColor: false,
	    onDatasetHover: {
	      highlightDataSeries: false,
	    },
	    theme: 'light',
	    style: {
	      fontSize: '16px',
	      fontFamily: 'Inter',
	    },
	    y: {
	      formatter: function (val) {
	        return val + " Team Members"
	      }
	    },
	  },
	};

	return (
		  <ApexChart
		    type="donut"
		    series={seriesPieChart}
		    options={optionsPieChart}
		  />

	);
};
// END LOCATION CHART

export const StatusDetailPieChart = (props) => {
	const {staticstatus} = props;
	const { 
		user,
		curLocation,
		curStatusGroup,
		endpoint
	} = useAuth();  

	const reloadTrigger = staticstatus ? [staticstatus] : [curStatusGroup, curLocation && curLocation.locationid];
	const [showStudentStatusModal, setShowStudentStatusModal] = useState(false);
	const [studentList, setStudentList] = useState([]);
	
	const [studentStatuses, setStudentStatuses] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState(null);
	
	useEffect(() => {
		  axios.get(endpoint+"/student/stats/statusdetail/default.asp", {
				  		params: {
				  			'companyid':user.companyid, 
				  			'ficusstatus':staticstatus ? staticstatus : curStatusGroup, 
				  			'locationid': curLocation && curLocation.locationid  
				  		}	
			  })  
			  .then(response => {
			  	const theResult = response.data;
			  	setStudentStatuses(theResult);
			  })
		  	.catch(error => console.log(error));
// eslint-disable-next-line react-hooks/exhaustive-deps
			}, reloadTrigger );

	const LabelClicked = (ficusstatus) => {
		setStudentList([]);
		setSelectedStatus(ficusstatus)
		setShowStudentStatusModal(true);
	}
	
  const chartSeries = Array.from(studentStatuses, function(m) { return m.statuscount });
  const chartLabels = Array.from(studentStatuses, function(m) { return m.StudentStatus });

	const seriesPieChart = chartSeries;
	

	const OptionsPieChart = (props) => {
		
		return({
			chart: {
				height: '100%',
				id: 'studentstatus',
	      events: {
	      	dataPointSelection: function(event, chartContext, config) {
	      			if (config.selectedDataPoints[0].length !== 0) {
	      				/* setSelectedStatus(config.w.config.labels[config.selectedDataPoints[0]]);  */
	      				LabelClicked(config.w.config.labels[config.selectedDataPoints[0]]);
	      		} else {
/*	      				alert("all locations selected")  */
	      		}
	      	}
	      },
			},

	    plotOptions: {
	      pie: {
	        startAngle: -180,
	        endAngle: 180,
	      	dataLabels: {
	      		offset: 0
	      	},
	        donut: {
	          size: '45%',
	          background: 'transparent',
	          labels: {
	          	show: false,
	          	name: {
	          		show:true,
	          	},
	          	total: {
	          		show:false,
	          		label: 'Students',
	          		fontSize: '.9rem',
			 					formatter: function (w) {
			 						let myTotal = (w.globals.seriesTotals.reduce((a, b) => {return a + b }, 0))
			            return myTotal
			          }         		
	          	}
	          }
	        }
	      }
	    },
		  dataLabels: {
		  	enabled: true,
		  	dropShadow: {
		    	enabled: true
		    },
		    style: { 
		    	fontSize: '14px',
		    	fontWeight: 'normal',
		    	colors: ['#fff']
		    },
		    background: {
		    	enabled: false,
		    	opacity: 1.0,
		    	foreColor: '#000',
		    	borderColor: '#888',
		    	dropShadow: {
		    		enabled: true
		    	}
		    },
			  formatter: function(value, { seriesIndex, dataPointIndex, w }) {
			    	return w.config.series[seriesIndex] 
			    /* 
			    	return w.config.series[seriesIndex] + " " + w.config.labels[seriesIndex]
			    	return w.config.labels[seriesIndex] 
			    */
			  }	  
		  },
		  legend: {
				 show: true,
		  	 position: 'right',
		  	 horizontalAlign: 'left',
		  	 fontSize: '13px',	
		  	 offsetY: 10,  	
		  	 offsetX: 0,
		      markers: {
		      	width: 15,
		      	height: 12,
		      	radius: 0
		      },
		      itemMargin: {
		          horizontal: 0,
		          vertical: 0,
		      },
  				tooltipHoverFormatter: function(seriesName, opts) {
        		return seriesName + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
    			}
		  },
/*
		  responsive: [{
		    breakpoint: 400,
		    options: {
		      legend: {
		        position: 'top',
		  	 		offsetY: 10,  	
			      itemMargin: {
			          horizontal: 3,
			          vertical: 0,
			      }
		      }
		    }
		  }],
*/

	
		  theme: {
		    monochrome: {
		      enabled: true,
		      shadeTo: 'light',
		      shadeIntensity: .9,
		      color: '#004488',
		    }
		  },
	
	  labels: 
	 		chartLabels,
		  tooltip: {
		  	enabled: true,
		    fillSeriesColor: false,
		    onDatasetHover: {
		      highlightDataSeries: false,
		    },
		    theme: 'light',
		    style: {
		      fontSize: '14px',
		      fontFamily: 'Inter',
		    },
		    y: {
		      formatter: function (val) {
		        return val + " Team Members"
		      }
		    },
		  },
		})
	};

	const ShowChart = () => {
		if (studentStatuses.length) {
			return (
			<div style={{height: ""}}>
			  <ApexChart 
			    type="donut"
			    series={seriesPieChart}
			    options={OptionsPieChart()} 
			    className="mb-xs-0 "
			    height="150"
		 		 />
	 		 </div>			
			)
		} else {
			return (
				<div className="small my-3 mx-5 text-center">
					<i>No Team Members are currently {curStatusGroup} at this store location. </i>
				</div>
			)
		}
	}
	  
	return (
		<>
		<ShowChart />
		<StudentStatusModal 
			showme={showStudentStatusModal} 
			studentlist={studentList} 
			setstudentlist={setStudentList} 			
			currentstatus={staticstatus ? staticstatus : curStatusGroup} 
			detailstatus={selectedStatus} 
			onClose={() => setShowStudentStatusModal(false)} />
		</>

	);			
}

// END STATUSPIECHART ORIGINAL

export const StatusDetailPieChartX = (props) => {
	const {staticstatus} = props;
	const { 
		user,
		curLocation,
		curStatusGroup,
		endpoint
	} = useAuth();  

	const reloadTrigger = staticstatus ? [staticstatus] : [curStatusGroup, curLocation && curLocation.locationid];
	const [showStudentStatusModal, setShowStudentStatusModal] = useState(false);
	const [studentList, setStudentList] = useState([]);
	
	const [studentStatuses, setStudentStatuses] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState(null);
	
	useEffect(() => {
		  axios.get(endpoint+"/student/stats/statusdetail/default.asp", {
				  		params: {
				  			'companyid':user.companyid, 
				  			'ficusstatus':staticstatus ? staticstatus : curStatusGroup, 
				  			'locationid': curLocation && curLocation.locationid  
				  		}	
			  })  
			  .then(response => {
			  	const theResult = response.data;
			  	setStudentStatuses(theResult);
			  })
		  	.catch(error => console.log(error));
// eslint-disable-next-line react-hooks/exhaustive-deps
			},reloadTrigger);

	const LabelClicked = (ficusstatus) => {
		setStudentList([]);
		setSelectedStatus(ficusstatus)
		setShowStudentStatusModal(true);
	}
	
  const chartSeries = Array.from(studentStatuses, function(m) { return m.statuscount });
  const chartLabels = Array.from(studentStatuses, function(m) { return m.StudentStatus });

	const seriesPieChart = chartSeries;
	

	const OptionsPieChart = (props) => {
		
		return({
			chart: {
				id: 'studentstatus',
	      events: {
	      	dataPointSelection: function(event, chartContext, config) {
	      			if (config.selectedDataPoints[0].length !== 0) {
	      				/* setSelectedStatus(config.w.config.labels[config.selectedDataPoints[0]]);  */
	      				LabelClicked(config.w.config.labels[config.selectedDataPoints[0]]);
	      		} else {
/*	      				alert("all locations selected") */
	      		}
	      	}
	      },
			},

	    plotOptions: {
	      pie: {
	        startAngle: -180,
	        endAngle: 180,
	      	dataLabels: {
	      		offset: 50
	      	},
	        donut: {
	          size: '45%',
	          background: 'transparent',
	          labels: {
	          	show: false,
	          	name: {
	          		show:true,
	          	},
	          	value: {
	         			offsetY: 5,
	          	},
	          	total: {
	          		show:false,
	          		label: 'Students',
	          		fontSize: '.9rem',
			 					formatter: function (w) {
			 						let myTotal = (w.globals.seriesTotals.reduce((a, b) => {return a + b }, 0))
			            return myTotal
			          }         		
	          	}
	          }
	        }
	      }
	    },
		  dataLabels: {
		  	enabled: true,
		  	dropShadow: {
		    	enabled: false
		    },
		    style: { 
		    	fontSize: '14px',
		    	fontWeight: 'normal',
		    	colors: ['#fff']
		    },
		    background: {
		    	enabled: true,
		    	opacity: 1.0,
		    	foreColor: '#000',
		    	borderColor: '#888',
		    	dropShadow: {
		    		enabled: true
		    	}
		    },
			  formatter: function(value, { seriesIndex, dataPointIndex, w }) {
			    	return w.config.series[seriesIndex] + " " + w.config.labels[seriesIndex]
			    /* 
			     	return w.config.series[seriesIndex]
			    	return w.config.labels[seriesIndex] 
			    */
			  }	  
		  },
		  legend: {
				 show: false,
		  	 position: 'right',
		  	 fontSize: '14px',	
		  	 offsetY: -0,  	
		      markers: {
		      	width: 10,
		      	height: 10,
		      	radius: 10
		      },
		      itemMargin: {
		          horizontal: 0,
		          vertical: -2,
		      },
		  },

	
		  theme: {
		    monochrome: {
		      enabled: true,
		      shadeTo: 'light',
		      shadeIntensity: .9,
		      color: '#004488',
		    }
		  },
	
	  labels: chartLabels,
	  responsive: [{
	    breakpoint: 350,
	    options: {
	      chart: {
	        width: '100%'
	      },
	      legend: {
	        position: 'right',
	        fontSize: '10px',
	      },
	      markers: {
	      	width: 10,
	      	height: 10,
	      	radius: 10
	      }
	    }
	  }],
		  tooltip: {
		  	enabled: false,
		    fillSeriesColor: false,
		    onDatasetHover: {
		      highlightDataSeries: false,
		    },
		    theme: 'light',
		    style: {
		      fontSize: '14px',
		      fontFamily: 'Inter',
		    },
		    y: {
		      formatter: function (val) {
		        return val + " Team Members"
		      }
		    },
		  },
		})
	};
	
	  
	return (
		<>
		<div style={{height: ""}}>
	  <ApexChart 
	    type="donut"
	    series={seriesPieChart}
	    options={OptionsPieChart()} 
	    className=""
	    height="150"
 		 />
 		 </div>
		<StudentStatusModal 
			showme={showStudentStatusModal} 
			studentlist={studentList} 
			setstudentlist={setStudentList} 
			currentstatus={staticstatus ? staticstatus : curStatusGroup} 
			detailstatus={selectedStatus} 
			onClose={() => setShowStudentStatusModal(false)} />
		</>

	);			
}

export const StatusDetailPieChart2 = (props) => {
	const {staticstatus} = props;
	const { 
		user,
		curLocation,
		curStatusGroup,
		endpoint
	} = useAuth();  

	const reloadTrigger = staticstatus ? [staticstatus] : [curStatusGroup, curLocation && curLocation.locationid];
	const [showStudentStatusModal, setShowStudentStatusModal] = useState(false);
	const [studentList, setStudentList] = useState([]);
	
	const [studentStatuses, setStudentStatuses] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState(null);
	const [themeColor, setThemeColor] = useState(null);
		
	useEffect(() => {
			switch (curStatusGroup) {
				case 'Inquiring': 
					setThemeColor('#1d5b8f');
					break;
				case 'Applying': 
					setThemeColor('#1b90b3');
					break;
				case 'Enrolled': 
					setThemeColor('#2b722b');
					break;
				default: 
					setThemeColor('#2b722b');
			}
		  axios.get(endpoint+"/student/stats/statusdetail/default.asp", {
				  		params: {
				  			'companyid':user.companyid, 
				  			'ficusstatus':staticstatus ? staticstatus : curStatusGroup, 
				  			'locationid': curLocation && curLocation.locationid,
				  			'hidelocations': "true" 
				  		}	
			  })  
			  .then(response => {
			  	const theResult = response.data;
			  	setStudentStatuses(theResult);
			  })
		  	.catch(error => console.log(error));
// eslint-disable-next-line react-hooks/exhaustive-deps
			}, reloadTrigger);

	const LabelClicked = (ficusstatus) => {
		setStudentList([]);
		setSelectedStatus(ficusstatus)
		setShowStudentStatusModal(true);
	}

	
  const chartSeries = Array.from(studentStatuses, function(m) { return m.statuscount });
  const chartLabels = Array.from(studentStatuses, function(m) { return m.StudentStatus });

	const seriesPieChart = chartSeries;
	
	const OptionsPieChart = (props) => {
		
		return({
			chart: {
				height: '100%',
				id: 'studentstatus',
	      events: {
	      	dataPointSelection: function(event, chartContext, config) {
	      			if (config.selectedDataPoints[0].length !== 0) {
	      				/* setSelectedStatus(config.w.config.labels[config.selectedDataPoints[0]]);  */
	      				LabelClicked(config.w.config.labels[config.selectedDataPoints[0]]);
	      		} else {
/*	      				alert("all locations selected")  */
	      		}
	      	},
		      legendClick: function(chartContext, seriesIndex, config) {
						
		      }
	      },
			},

	    plotOptions: {
	      pie: {
	        startAngle: -180,
	        endAngle: 180,
	      	dataLabels: {
	      		offset: 0
	      	},
	        donut: {
	          size: '45%',
	          background: 'transparent',
	          labels: {
	          	show: false,
	          	name: {
	          		show:true,
	          	},
	          	total: {
	          		show:false,
	          		label: 'Students',
	          		fontSize: '.9rem',
			 					formatter: function (w) {
			 						let myTotal = (w.globals.seriesTotals.reduce((a, b) => {return a + b }, 0))
			            return myTotal
			          }         		
	          	}
	          }
	        }
	      }
	    },
		  dataLabels: {
		  	enabled: true,
		  	dropShadow: {
		    	enabled: true
		    },
		    style: { 
		    	fontSize: '14px',
		    	fontWeight: 'normal',
		    	colors: ['#fff']
		    },
		    background: {
		    	enabled: false,
		    	opacity: 1.0,
		    	foreColor: '#000',
		    	borderColor: '#888',
		    	dropShadow: {
		    		enabled: true
		    	}
		    },
			  formatter: function(value, { seriesIndex, dataPointIndex, w }) {
			    	return w.config.series[seriesIndex] 
			    /* 
			    	return w.config.series[seriesIndex] + " " + w.config.labels[seriesIndex]
			    	return w.config.labels[seriesIndex] 
			    */
			  }	  
		  },
		  title: {
		    text: curStatusGroup+" Statuses",
		    align: 'right',
		    margin: 10,
		    offsetX: 0,
		    offsetY: 35,
		    floating: false,
		    style: {
		      fontSize:  '14px',
		      fontWeight:  'bold',
		      fontFamily:  undefined,
		      color:  '#263238'
		    }
		  },
		  legend: {
				 show: true,
		  	 position: 'right',
		  	 horizontalAlign: 'left',
		  	 fontSize: '13px',	
		  	 offsetY: 40,  	
		  	 offsetX: 0,
		      markers: {
		      	width: 15,
		      	height: 12,
		      	radius: 3
		      },
		      itemMargin: {
		          horizontal: 0,
		          vertical: 0,
		      },
/*
    			tooltipHoverFormatter: function(seriesName, opts) {
        		return seriesName + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
    			}
*/
		  },
/*
		  responsive: [{
		    breakpoint: 400,
		    options: {
		      legend: {
		        position: 'top',
		  	 		offsetY: 10,  	
			      itemMargin: {
			          horizontal: 3,
			          vertical: 0,
			      }
		      }
		    }
		  }],
*/

	
		  theme: {
		    monochrome: {
		      enabled: true,
		      shadeTo: 'light',
		      shadeIntensity: .9,
		      color: themeColor
		    }
		  },
	
	  labels: 
	 		chartLabels,
		  tooltip: {
		  	enabled: true,
		    fillSeriesColor: false,
		    onDatasetHover: {
		      highlightDataSeries: false,
		    },
		    theme: 'light',
		    style: {
		      fontSize: '14px',
		      fontFamily: 'Inter',
		    },
		    y: {
		      formatter: function (val) {
		        return val + " Team Members"
		      }
		    },
		  },
		})
	};

	const ShowChart = () => {
		if (studentStatuses.length) {
			return (
			<div style={{height: ""}}>
			  <ApexChart 
			    type="donut"
			    series={seriesPieChart}
			    options={OptionsPieChart()} 
			    className="mb-xs-0 "
			    height="150"
		 		 />
	 		 </div>			
			)
		} else {
			return (
				<div className="small my-3 mx-5 text-center">
					<i>No Team Members are currently {curStatusGroup} at this store location. </i>
				</div>
			)
		}
	}
	  
	return (
		<>
		<ShowChart />
		<StudentStatusModal 
			showme={showStudentStatusModal} 
			studentlist={studentList} 
			setstudentlist={setStudentList} 			
			currentstatus={staticstatus ? staticstatus : curStatusGroup} 
			detailstatus={selectedStatus} 
			onClose={() => setShowStudentStatusModal(false)} />
		</>

	);			
}
