
import React, { useState, useEffect } from "react";
import axios from "axios";
import { AcademicCapIcon, DocumentDuplicateIcon} from "@heroicons/react/solid";
import { InformationCircleIcon, InboxInIcon, UserGroupIcon, DocumentTextIcon} from "@heroicons/react/outline";
import { Card, ListGroup, Row, Col, Image, Nav, Form, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { StudentProcessModal } from "components/FicusModals.js";
import { VerificationTable } from "components/FicusTables.js";
import { useNavigate } from 'react-router-dom';

import { useAuth } from "pages/access/useAuth"; 
import CFALogo from "assets/img/logos/icon_circle-cfa-logo-small.png";

import CTAMarketingMaterialsImage from "assets/img/icons/CTAMarketingMaterials-gray.png"; 
import CTAConnectImage from "assets/img/icons/CTAConnect-gray.png"; 
import CTATranscriptsImage from "assets/img/icons/CTATranscripts-gray.png"; 
import CTAMapPin from "assets/img/icons/CTAMapPin-gray.png"; 
import LogoFacebook from "assets/img/icons/logo-facebook-green.png"; 
import LogoInstagram from "assets/img/icons/logo-instagram-green.png"; 
import LogoYoutube from "assets/img/icons/logo-youtube-green.png"; 
import LogoLinkedIn from "assets/img/icons/logo-linkedin-green.png"; 

const InquiringIcon = InboxInIcon;
const ApplyingIcon = DocumentDuplicateIcon;
const EnrolledIcon = AcademicCapIcon;

export const LocationPillsWidget = (props) => { 
	const { user, setCurLocation, endpoint } = useAuth(); 	
	
	const [storeLocations, setStoreLocations] = useState([]);

	useEffect(() => {
	  axios.get(endpoint+"/company/stats/locations/default.asp", {
			  		params: { 'companyid': user.companyid }	
		  })  
		  .then(response => {
		  	const theResult = response.data;
		  	setStoreLocations(theResult); 
		  })
	  	.catch(error => console.log(error));	
	},[user, endpoint]);

  const StoreLocation = (props) => {
    const { Name, studentcount, LocationID } = props;
		
    return (
      <Nav.Item onClick={() => {setCurLocation({locationid: LocationID, locationname: Name})}}>
        <Nav.Link eventKey={LocationID}>{Name}<span className="d-none"> {studentcount}</span></Nav.Link>
      </Nav.Item>
    );
  }

  return (
    <Nav fill variant="pills" defaultActiveKey="all-locs" className="smallpills flex-xs-row">
    
      <Nav.Item onClick={() => {setCurLocation(null)}}>
        <Nav.Link eventKey="all-locs" >All Stores</Nav.Link>
      </Nav.Item>
      {storeLocations.map(st => <StoreLocation key={`location-${st.LocationID}`} {...st} />)}
    </Nav>        
   );
};



export const LocationListWidget = (props) => { 
	const { initialText } = props
	const { user, setCurLocation, endpoint } = useAuth(); 	
	
	const [storeLocations, setStoreLocations] = useState([]);

	useEffect(() => {
	  axios.get(endpoint+"/company/stats/locations/default.asp", {
			  		params: { 'companyid': user.companyid }	
		  })  
		  .then(response => {
		  	const theResult = response.data;
		  	setStoreLocations(theResult);
		  })
	  	.catch(error => console.log(error));	
	},[user, endpoint]);

  const StoreLocation = (props) => {
    const { Name, studentcount, LocationID } = props;
		
    return (
      <ListGroup.Item href={'#'+LocationID} className=" border-bottom py-1 px-2" action onClick={() => {setCurLocation({locationid: LocationID, locationname: Name})}}>
        <Row className="align-items-center" >
					<Col xs="auto">
					  <Card className="" >
					  	<Image src={CFALogo}  style={{height:'1.2rem', width:'1.2rem'}} className="rounded-circle border-white" />
					  </Card>
					</Col>
					<Col xs="auto" className="px-0">
					  <span className="mb-0">
					      {Name}
					  </span>
					</Col>
					<Col className="text-end">
					  <span className="text-dark">
					    {studentcount} Active
					  </span>
					</Col>
        </Row>
      </ListGroup.Item>
    );
  }

  return (
  
<ListGroup className="locationlist" style={{fontSize: 'small'}} defaultActiveKey="#foo">
    <ListGroup.Item  href="#foo" className=" border-bottom py-1 px-2" action onClick={() => {setCurLocation(null)}}>
      <Row className="align-items-center" >
					<Col xs="auto">
					  <Card className="" >
					  </Card>
					</Col>
				<Col xs="auto" className="px-0">
				  <span className="mb-0">
				      { (initialText) ? initialText : user.companyname }
				  </span>
				</Col>
				<Col className="text-end">
				  <span className="text-dark">
              {storeLocations.reduce((n, {studentcount}) => n + studentcount, 0)} Active
				  </span>
				</Col>
      </Row>
    </ListGroup.Item>
    {storeLocations.map(st => <StoreLocation key={`location-${st.LocationID}`} {...st} />)}
   </ListGroup >
   );
};


// STORE WIDGET WITH CARDS
export const StoreLocationsWidgetCards = (props) => { 
	const { user, setCurLocation, endpoint } = useAuth(); 	
	
	const [storeLocations, setStoreLocations] = useState([]);

	useEffect(() => {
	  axios.get(endpoint+"/company/stats/locations/default.asp", {
			  		params: { 'companyid': user.companyid }	
		  })  
		  .then(response => {
		  	const theResult = response.data;
		  	setStoreLocations(theResult);
		  })
	  	.catch(error => console.log(error));	
	},[user, endpoint]);

  const StoreLocation = (props) => {
    const { Name, studentcount, LocationID } = props;

    return (
      <ListGroup.Item className="bg-transparent border-bottom py-1 px-0">
        <Row className="align-items-center" onClick={() => {setCurLocation({locationid: LocationID, locationname: Name});}}>
					<Col xs="auto">
					  <Card className="avatar-sm" >
					  	<Image src={CFALogo}  style={{height:'1.0rem', width:'1.0rem'}} className="rounded-circle border-white" />
					  </Card>
					</Col>
					<Col xs="auto" className="px-0">
					  <span className="mb-0">
					      {Name} {LocationID}
					  </span>
					</Col>
					<Col className="text-end">
					  <span className="text-dark">
					    {studentcount} Active
					  </span>
					</Col>
        </Row>
      </ListGroup.Item>
    );
  }

  return (
    <Card border="0"className="shadow locationCard"> 
      <Card.Header className="py-2 border-bottom">
        <Row className="align-items-center" onClick={() => {setCurLocation(null);}}>
          <Col xs="auto">
            <Card.Link href="#" className="avatar-sm">

            </Card.Link>
          </Col>
          <Col xs="auto" className="px-0">
            <h4 className="fs-6 text-dark mb-0">
              <Card.Link>
          		 {user.companyname}
              </Card.Link>
            </h4>
          </Col>
          <Col className="text-end">
            <span className="fs-6 fw-bolder text-dark">
              {storeLocations.reduce((n, {studentcount}) => n + studentcount, 0)} Active
            </span>
          </Col>
        </Row>
        <h2 className="fs-5 fw-bold mb-0">
        </h2>
      </Card.Header>
      <Card.Body className="py-0">
        <ListGroup className="list-group-flush" style={{fontSize:'small'}}>
          {storeLocations.map(st => <StoreLocation key={`location-${st.LocationID}`} {...st} />)}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};




export const StoryWidget = (props) => {
	const { 
		user,
		curLocation,
		setCurStatusGroup, 
		endpoint
	} = useAuth();  
	
	const navigate = useNavigate();

	const reloadTrigger = curLocation && curLocation.locationid;
	const [showProcessModal, setShowProcessModal] = useState(false);
	const [studentStatuses, setStudentStatuses] = useState([]);

	function StoryCard(props) {
		const { iconName, title, displayValue } = props;
		const StoryCardIcon = iconName;
		
		return (
			<Col className="tight xs-4" onClick={() => {setCurStatusGroup(title)}}>
		    <Card className={`storycard align-items-center ${title.toLowerCase()}`} >
		      <Card.Body className="center" style={{textAlign:"-webkit-center"}}>
						<StoryCardIcon className="icon icon-xs ms-1 storyicontop" stroke="white" />
	      		<div className="d-flex py-1">
			       	<div className="fw-normal mb-0 px-1 text-white" > 
			       		<span className="h6 me-1">{displayValue}</span>
			       		{title}
			       	</div>
							<StoryCardIcon className="icon icon-xs ms-1 storyiconright" stroke="white" />
		        </div>
{/*
	
	        <div className="d-flex ps-3">
		          <i style={{ fontSize : '0.5rem' }} >
		            {period}
		          </i>
		        </div>
*/}
		      </Card.Body>
		    </Card>
	    </Col>
		)
	}
	
	function getCountByKey(arr, key, value, countkey) {
	  for (var i=0, iLen=arr.length; i < iLen; i++) {
	    if (arr[i][key] === value) {
	    	return arr[i][countkey]
	    }    		
	  }
		return '0'
	}
	
	useEffect(() => {
	  axios.get(endpoint+"/student/stats/status/default.asp", {
			  		params: { 'companyid': user.companyid, 'locationid': curLocation && curLocation.locationid }	
		  })  
		  .then(response => {
		  	const theResult = response.data;
		  	setStudentStatuses(theResult);
		  	if (!theResult.find(foo => foo.ficusstatus === 'Applying')) {
					if (theResult.find(foo => foo.ficusstatus === 'Enrolled')) {
						setCurStatusGroup('Enrolled');
					} else if (theResult.find(foo => foo.ficusstatus === 'Inquiring')) { 
							setCurStatusGroup('Inquiring');
					}
		  	}
		  })
	  	.catch(error => console.log(error));	
// eslint-disable-next-line react-hooks/exhaustive-deps
	},[reloadTrigger]);

  return (
  <>
    <Row xs={12} md={8} >  
    	<Card className="text-start mt-3 mb-3 py-2" style={{boxShadow: "3px 3px 3px lightgray"}}>  	
    	<h6 className="mb-0">
    		<b>{studentStatuses.reduce((total, ss) => { return total + ss.statuscount }, 0)} Active Team Members</b>
				<OverlayTrigger placement="bottom" key="activeinfo" overlay={<Tooltip id="activeinfo" className="m-0 " style={{fontSize:"0.4rem"}}>Active Team Members are currently enrolled or in the admissions process or have been deferred, dropped, or withdrawn in the past 90 days </Tooltip>}>
        	<InformationCircleIcon className="icon icon-xxs mb-2" style={{marginLeft: "2px", height: "0.9rem", stroke:"#0059B3", strokeWidth:"2px"}} />
        </OverlayTrigger >
    	</h6>
    	<div className="mt-n1" style={{fontSize: "0.8rem"}}><i>{(user && user.locationList.length > 1) ? (curLocation) ? (curLocation && curLocation.locationname) : 'All Locations' : ''}</i>
    	</div>
      <div className="btn btn-default text-start p-0" onClick={() => {setShowProcessModal(true)}} style={{fontSize:'0.7rem', color:'#0059B3'}}>
      	Process Guide <DocumentTextIcon className="icon icon-xxs" style={{strokeWidth:"2", verticalAlign:'text-bottom'}}/>
    		<div className="float-end" onClick={()=> navigate('/students.js', {replace: true})}>Team Member List<UserGroupIcon className="icon icon-xxs ms-1 storyiconright" style={{strokeWidth:"2", color:'#0059B3', verticalAlign:'text-bottom'}} /></div>
      </div>
    	</Card >
    </Row>
    <Row xs={12} md={8} border="0" className="mt-0 mb-3">
    	<StoryCard iconName={InquiringIcon} cardColor="SteelBlue" title='Inquiring' displayValue={getCountByKey(studentStatuses, 'ficusstatus', 'Inquiring', 'statuscount')} period='Last TBD Days'/>
    	<StoryCard iconName={ApplyingIcon} cardColor="Peru" title='Applying' displayValue={getCountByKey(studentStatuses, 'ficusstatus', 'Applying', 'statuscount')} period='Since TBD'/>
    	<StoryCard iconName={EnrolledIcon} cardColor="Teal" title='Enrolled' displayValue={getCountByKey(studentStatuses, 'ficusstatus', 'Enrolled', 'statuscount')} period='Since TBD'/>
    </Row>
    <StudentProcessModal show={showProcessModal} onHide={() => {setShowProcessModal(false)}}/>
  </>
  );
};


export const LocationDropDownWidget = (props) => { 
	const { initialText } = props
	const { user, curLocation, setCurLocation, endpoint } = useAuth(); 	
	
	const [storeLocations, setStoreLocations] = useState([]);

	useEffect(() => {
	  axios.get(endpoint+"/company/stats/locations/default.asp", {
			  		params: { 'companyid': user.companyid }	
		  })  
		  .then(response => {
		  	const theResult = response.data == null ? '' : response.data;
		  	setStoreLocations(theResult);
		  })
	  	.catch(error => console.log(error));	
	},[user, curLocation, endpoint]);

  const StoreLocation = (props) => {
    const { Name, LocationID } = props;
		
    return (
				<option value={LocationID}>{Name}</option>
    );
  }
  
  const changeLocation = (e) => {
    const newLoc = e.target.value;
		const index = e.nativeEvent.target.selectedIndex;
		const text =e.nativeEvent.target[index].text;    
		
		if (newLoc) {
    	setCurLocation({locationid: newLoc, locationname: text});
  	} else {
  		setCurLocation(null);
  	}
  };
  
  if (storeLocations.length === 1) {
  	return (
			<div className="d-flex justify-content-center pt-0 ">
				<div className="d-inline-flex me-1" style={{fontSize: 'small'}}>Location: {storeLocations[0].Name}</div>
			</div>
  	)
  } else if (storeLocations.length > 1) {
 
	  return (
			<div className="d-flex justify-content-center pt-0 pb-1 ">
				<div className="d-inline-flex me-1" style={{fontSize: 'small'}} >Location: </div>
				<Form.Select size="sm" value={(curLocation) ? curLocation.locationid : ''} 
					className="py-0 pe-4" 
					style={{width:"inherit", height:"1.25rem", fontSize: 'small', borderRadius:'0.25rem', backgroundPosition:'right 0.3rem center'}}
					onChange={changeLocation}>
{/*					<option value="">{user.companyname}</option>  */}
						<option value="">{ (initialText) ? initialText : 'All Locations' }</option>
	    		{storeLocations.map(st => <StoreLocation key={`location-${st.LocationID}`} {...st} />)}
				</Form.Select>
			</div>

	   );
 	} else {
 		return(<div>No Locations Found</div>)
	}
};

export const VerficationWidget = (props) => {
	const { 
		user,
		curLocation,
		endpoint
	} = useAuth();  
	
	const [showVerficationWidget, setShowVerficationWidget] = useState('none');
	const [verificationList, setVerificationList] = useState([]);
	const [rerender, setRerender] = useState(false); /* This funky bit forces rerender of the table when employee status is updated */
	
	useEffect(() => {

	  axios.get(endpoint+"/student/verification.asp", {
			  		params: { 
			  			'companyid':user.companyid,  
			  			'locationid': curLocation && curLocation.locationid
			  		}	
		  })  
		  .then(response => {
		  	const theResult = response.data;
		  	setVerificationList(theResult);
		  	if (theResult.length >= 1) {
		  		setShowVerficationWidget('block')
		  	}
		  })
	  	.catch(error => console.log(error));

// eslint-disable-next-line react-hooks/exhaustive-deps
	},[rerender]);

  return (
  <>
    <Row xs={12} md={8} style={{display: showVerficationWidget}} >  
    	<Card className="text-start mt-2 mb-0 py-2" style={{boxShadow: "3px 3px 3px lightgray"}}>  	
      <Card.Header className="py-1 mb-1 border-bottom" style={{backgroundColor: '#c72727', marginLeft: '-0.7rem', marginRight:'-0.8rem' }}>
	    	<h6 className="mb-0" style={{color: 'white'}}><b>Employment Verification</b></h6>
    		<div style={{fontSize:'0.7rem'}}>
    			Please mark the following Team Members as Eligible or Not Eligible to participate in ESHE program 
    		</div>
			</Card.Header>
	      {verificationList && VerificationTable(verificationList, setVerificationList, rerender, setRerender)}    
    	</Card >
    </Row>
  </>
  );
};

export const CallToAction = (props) => {  

	return (
		<>	

{/*
			<CTAButton title="Team Member Graduates" link="" icon={CTATranscriptsImage} /> 
*/}
			<CTAButton title="Refer an Operator" link="https://ficuseducation.com/referrals" icon={CTAConnectImage} /> 
			<CTAButton title="Marketing Materials" link="https://ficuseducation.com/marketingmaterials" icon={CTAMarketingMaterialsImage} /> 
			<CTAButton title="The 5000 Futures Initiative" link="https://5000futures.org/impact/#map" icon={CTAMapPin} /> 
			<div class="text-center">
				<div style={{fontSize:'smaller', marginTop: '0.5rem', marginBottom: '0.3rem'}} class="me-3">FOLLOW US ON SOCIAL MEDIA</div>
				<Button variant="socialmedia" className="me-3" title="LinkedIn" href="https://www.linkedin.com/company/ficus-group" target="_blank">
					<Image src={LogoLinkedIn} className="" style={{marginBottom:'1px'}} />
				</Button>
				<Button variant="socialmedia" className="me-3" title="Instagram" href="https://instagram.com/ficus.education" target="_blank">
					<Image src={LogoInstagram} className=""/>
				</Button>
				<Button variant="socialmedia" className="me-3" title="Facebook" href="https://www.facebook.com/profile.php?id=61554780260347" target="_blank">
					<Image src={LogoFacebook} className=""/>
				</Button>
				<Button variant="socialmedia" className="me-3 " title="Youtube" href="https://www.youtube.com/channel/UCEHh16qTFyzIwh-UC_aUcvA" target="_blank">
					<Image src={LogoYoutube} className=""/>
				</Button>
	  </div>
	  </>
	)
};

export const CTAButton = (props) => { 
	const { title, link, icon } = props;
	return (
	  <Button size="lg" variant="cta" className="ps-4 me-3 mb-3 text-start" href={link} target="_blank">
			<Image src={icon} className="me-3"/>
	    {title}
	  </Button>
  );
}