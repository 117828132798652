import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { Container, Col, Row, Card, Form, Button } from 'react-bootstrap';
import { SearchIcon, LogoutIcon, HomeIcon, ExternalLinkIcon } from "@heroicons/react/outline";
import Table from "react-bootstrap-table-next";
import Pagination from "react-bootstrap-table2-paginator";
import * as Paginator from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { useAuth  } from "pages/access/useAuth"; 
import Cookies from 'universal-cookie'; 
const cookies = new Cookies();

const { SearchBar } = Search;

export default () => { 
	const { 
		user,
		endpoint
	} = useAuth();

	const auth = useAuth();
	const [searchterm, setSearchTerm] = useState("");
	const [companies, setCompanies] = useState([])

	const clist = cookies.getAll();
	
	useEffect(() => {
		  axios.get(endpoint+"/company/quicklist.asp", {
	  		params: { 
	  			'searchterm': searchterm
	  			}	
			  })  
			  .then(response => {
			  	const theResult = response.data;
			  	setCompanies(theResult);
			  })
		  	.catch(error => console.log(error));	
	},[searchterm]);	
	
  return (
    <main>
      <section className="d-flex align-items-center vh-lg-100 mt-lg-0 bg-soft">
        <Container>
{/*			    <Card border="0" className=" mb-0">
			      <Card.Body>
			       	<h5 className="mt-4 mb-2">Search for Company/Operator </h5>
		          <Row>
		            <Col md={6} className="mb-3">
							    <InputGroup>
		                <Form.Control required type="text" placeholder="Enter Company or Operator Name" 
		                		onBlur={(e) => setSearchTerm(e.target.value)} 
		                		onKeyPress={e => e.key === 'Enter' && e.currentTarget.blur()}
 										/>
							      <InputGroup.Text><SearchIcon className="icon icon-xs" /></InputGroup.Text>
							    </InputGroup>
		            </Col>
		          </Row>
						</Card.Body>
			    </Card>
        	<hr className="my-1"></hr>
*/}			    
			    <Card border="0" className=" mb-0 ">
			    	<Card.Header className="border-bottom-0 pb-0">
				    	<h2 className="d-inline-block" style={{color:'#68a543'}}>
								<Button size="sm" className="float-end" onClick={() => auth.signOut() } style={{marginTop:'0.8rem'}}>Sign Out <LogoutIcon className="ms-1" style={{height:"1.5rem"}} /></Button>
								Ficus { (user.persontype === 100) ? 'Super' : 'Partner' } Admin Access to Operator Dashboards
							</h2>
			    	</Card.Header>
			      <Card.Body className="px-0">
		          <Row>
		            <Col md={12} className="mb-3">
									<ResultTable companies={companies}/>
		            </Col>
		          </Row>
						</Card.Body>
			    </Card>
        </Container>
      </section>
    </main>

  );
};

/* BOOTSTRAP TABLE */

export const ResultTable  = (props) => { 
	const { companies } = props;
	const {
		sectoken,
		user, 
		setUser,
		endpoint,
		adminUser,
		setCurLocation
	} = useAuth();
	
	const auth = useAuth();
	const navigate = useNavigate();

	function linkFormatter(cell, row) {
	  return (
	    <ExternalLinkIcon className="icon icon-xs me-1" 	    />
	  );
	}
	
	function smallColumnStyle(cell, row) {
		return { width: '50px', paddingLeft:'0px', paddingRight:'0px', textAlign:'center' }; 		
	}
	
	function column50pctStyle(cell, row) {
		return { width: '50%'}; 		
	}
		const columns = [
	  { dataField: "companyid", text: "Open", 
	  	formatter: linkFormatter,
			style: smallColumnStyle,
			headerStyle: smallColumnStyle,
 		  events: {
		    onClick: (e, column, columnIndex, row, rowIndex, value) => {
					openDashboard(row.operatoremail, row.password);;
		    }
		  }
	  },
	  { dataField: "name", text: "Company", 
			style: column50pctStyle,
			headerStyle: column50pctStyle
	  }, 
	  { dataField: "firstname", text: "First Name" },
	  { dataField: "lastname", text: "Last Name"  },
	  { dataField: "operatoremail", text: "Email", hidden: true  }, 
	  { dataField: "personid", text: "personid", width: "100", hidden: true  }, 
	  { dataField: "persontypeid", text: "persontypeid", width: "100", hidden: true  }, 
	  { dataField: "hscontactid", text: "hscontactid", width: "100", hidden: true  }, 
	  { dataField: "hscompanyid", text: "hscompanyid", width: "100", hidden: true  }, 
	  { dataField: "locationlist", text: "locationlist", width: "100", hidden: true  },
	  { dataField: "password", text: "password", width: "100", hidden: true  } 
	];

	const customTotal = (from, to, size) => (
	  <div style={{color:'gray'}}>
	    Showing {from} to {to} of {size} companies
	  </div>
	);

	const customSizePerPage = (props) => {
	  const { options, currentSizePerPage, onSizePerPageChange } = props;

	  const onPageChange = (e) => {
	    const page = e.target.value;
	    onSizePerPageChange(page);
	  }

	  return (
	      <Col xs="auto">
	        <Form.Select value={currentSizePerPage} onChange={onPageChange} className="pe-5">
	          {options.map(o => (
	            <option key={o.page} value={o.page}>
	              {o.text}
	            </option>
	          ))}
	        </Form.Select>
	      </Col>
	  );
	};

	function openDashboard(email, password) {
		cookies.remove('uid', { path: '/', domain: window.location.hostname  });
  
		setUser(null);		
    try {
	    axios.get(endpoint+"/users/signin/signout.asp")
	    .then(response => {
	    	const adminacct = (adminUser) ? adminUser : {user: user.uid, email: user.email, firstname: user.firstname, lastname: user.lastname, persontype: user.persontype, role: (user.persontype === 100) ? 'Super' : 'Partner' };
				cookies.set('adminuser', adminacct, { path: '/', domain: window.location.hostname  } );
			  auth.signIn({email: email, pwd: password, adminUser: adminacct })
				.then(response => {
					if (response === 'active'|| response === 'resetpwd' ) {
//							window.open("/dashboard/dashboardoperator.js", "_blank", "noreferrer")
						  navigate('/dashboard/dashboardoperator.js');
					}
//						setAcctStatus(response);
				});			  
	    })
				.catch(error => console.log(error)); 
    } catch {
			return('axios failed')
		}
		
	}

	const rowEvents = {
	  onDoubleClick: (e, row, rowIndex) => {
	  	openDashboard(row.operatoremail, row.password);
	  }
	};
	
	
	return (
	  <ToolkitProvider
	    keyField="companyid"
	    search={true}
	    columns={columns}
	    data={companies}
	    wrapperClasses="table-responsive"
	  >
	    {({ baseProps, searchProps }) => (
	      <Paginator.PaginationProvider pagination={
	        Pagination({
	          custom: true,
	          sizePerPageList: [20, 50, 100],
	          showTotal: true,
	          alwaysShowAllBtns: true,
	          totalSize: companies.length,
	          paginationTotalRenderer: customTotal,
	          sizePerPageRenderer: customSizePerPage
	        })
	      }>
	        {({ paginationProps, paginationTableProps }) => (
	          <Card>
	            <div className="table-responsive">
	              <Card.Header className="py-0 border-bottom-0">
	                <Row>
	                  <Col xs={12} md={6} className="d-flex align-items-center py-n1">
	                    <Paginator.PaginationTotalStandalone {...paginationProps} />
	                  </Col>
	                  <Col xs={12} md={6} className="justify-content-md-end py-n1">
	                    <SearchBar {...searchProps} />
	                  	<div style={{color:'gray', marginTop: '-5px', fontSize: '12px', paddingnLeft: '5px'}}>Search by Company or Operartor Name</div>
	                  </Col>
	                </Row>
	              </Card.Header>

	              <Table 
	                {...baseProps} 
	                {...paginationTableProps} 
	                condensed = { true }
	                bodyClasses="" 
	                rowClasses="border-bottom company-select"
	                classes="mt-1 dataTable-table"
	                rowEvents = { rowEvents } 
	              />

	              <Card.Footer>
	                <Row>
	                  <Col xs={12} sm={3} className="py-1">
	                    <Paginator.SizePerPageDropdownStandalone {...paginationProps} />
	                  </Col>
	                  <Col xs={12} sm={9} className="d-flex justify-content-md-end align-items-center mb-0 py-1">
	                    <Paginator.PaginationListStandalone {...paginationProps} />
	                  </Col>
	                </Row>
	              </Card.Footer>
	            </div>
	          </Card>
	        )}
	      </Paginator.PaginationProvider>
	    )}
	  </ToolkitProvider>
	);
}