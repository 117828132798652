
import React from "react";
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import { Col, Row, Card, Container } from 'react-bootstrap';
import { Link, useSearchParams } from 'react-router-dom';
import { PasswordForm } from "components/FicusForms";

import { myRoutes } from "routes";
import { useAuth } from "pages/access/useAuth"; 

import FicusLogo from "assets/img/logos/ficus_group.png";

export default () => {
	const { 
		user,
	} = useAuth();

	const [searchParams] = useSearchParams(); 
	const reset_guid = searchParams.get("reset_guid");		
	const resetLinkTimedOut = (reset_guid && !(user && user.email));


	function TimeOutMsg() {
		return(
			<>
	      <h3 className="mb-4">This Password Reset Link has timed out.</h3>
	      <div className="d-flex justify-content-center align-items-top mb-4">
					<Card.Link as={Link} to={myRoutes.ResetPassword.path} className="small btn btn-primary text-end">Request a new Reset Link</Card.Link>
				</div>
			</>
		)
	}

	
  return (
    <main>
      <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
	        <p className="text-center">
	          <Card.Link as={Link} to={myRoutes.Signin.path} className="d-flex align-items-center justify-content-center">
	            <ArrowNarrowLeftIcon className="icon icon-xs me-2" /> Back to sign in
	          </Card.Link>
	        </p>
          <Row className="justify-content-center">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow border-0 rounded p-4 p-lg-5 w-100 fmxw-500">
              	{ !resetLinkTimedOut ? <PasswordForm/> : <TimeOutMsg/> }
              </div>
            </Col>
          </Row>
          <div className="text-center text-md-center mb-4 mt-4">
	          <a href="https://ficuseducation.com" rel="noopener noreferrer" target="_blank">
	          	<img src={FicusLogo} alt="Ficus Education" title="Ficus Education WebSite" style={{maxWidth: "250px"}}/> 
	          </a>
          </div>
        </Container>
      </section>
    </main>
  );
};
