import React, { useState } from "react";
import { Modal, Col, Row, Form, Button, ListGroup, Alert } from 'react-bootstrap';

import { AcademicCapIcon, DocumentDuplicateIcon, DocumentTextIcon} from "@heroicons/react/solid";
import { InboxInIcon, CheckIcon, ExclamationIcon, XIcon, ChatIcon, MailIcon} from "@heroicons/react/outline";
import axios from "axios";
import { useAuth } from "pages/access/useAuth"; 

export const StudentProcessModal = (props) => {
  const { show = false } = props;  

  const onHide = () => props.onHide && props.onHide();

  return (
    <Modal as={Modal.Dialog} centered show={show} onHide={onHide}>
	    <Modal.Header>
	      <Modal.Title>
	      	<h5>Application/Enrollment Process</h5>
	      </Modal.Title>
	      <Button variant="close" aria-label="Close" onClick={onHide} />
	  	</Modal.Header>
      <Modal.Body className="px-0">
      	<StudentStatusTimeline />
      </Modal.Body>
		</Modal>
	);
};

export const StudentStatusTimeline = (props) => {

  const Inquiry = (props) => {
    return (
      <ListGroup.Item className="border-o pe-0">
        <Row className="ps-lg-1">
          <Col xs="auto">
            <div className={`icon-shape icon-xs icon-inquiring rounded`}>
              <InboxInIcon />
            </div>
          </Col>
          <Col className="ms-n2 mb-3">
            <h5 className="fw-bold mb-1">
              Inquiring Phase
            </h5>
		        <ListGroup className="list-group-flush list-group-timeline">
      				<ListGroup.Item className="border-o">
		          	<div>New Inquiry</div>
		          	<div className="timeline-detail">
		          		A new inquiry is created when a team member submits a Get Started form.  
		          		Inquiries are forwarded to Point University for followup and to start the application process.
		          	</div>
      				</ListGroup.Item>
		        </ListGroup>          
		       </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  const Application = (props) => {
    return (
      <ListGroup.Item className="border-o  pe-0">
        <Row className="ps-lg-1">
          <Col xs="auto">
            <div className={`icon-shape icon-xs icon-applying rounded`}>
              <DocumentDuplicateIcon />
            </div>
          </Col>
          <Col className="ms-n2 mb-3">
            <h5 className="fw-bold mb-1">
              Applying Phase
            </h5>
		        <ListGroup className="list-group-flush list-group-timeline">
      				<ListGroup.Item className="border-o">
		          	<div>App Started</div>
		          	<div className="timeline-detail">
		          		Team member has started filling out the online admissions application but has not yet submitted the application form.
		          	</div>
      				</ListGroup.Item>
      				<ListGroup.Item className="border-o">
		          	<div>App Completed</div>
		          	<div className="timeline-detail">
		          		Team member has completed and submitted their online application form.
		          	</div>
      				</ListGroup.Item>
      				<ListGroup.Item className="border-o">
		          	<div>Accepted</div>
		          	<div className="timeline-detail">
		          		Team Member application has been reviewed and accepted by the education partner and is now ready to move to enrollment.
		          	</div>		          	
      				</ListGroup.Item>
      				<ListGroup.Item className="border-o">		          	
		          	<div>Deferred/Denied/Dropped</div>
		          	<div className="timeline-detail">
		          		The application process has been delayed or terminated.
		          	</div>
		          	<div className="timeline-detail ms-4" style={{fontSize:'x-small'}}>
		          		<b>Deferred</b> The application process is delayed because the student has been requested to submit additional materials for review.
		          	</div>
		          	<div className="timeline-detail ms-4" style={{fontSize:'x-small'}}>
		          		<b>Denied</b> Student has been denied admission with recommendations to take courses at another institution.  Student may reapply in the future.
		          	</div>
		          	<div className="timeline-detail ms-4" style={{fontSize:'x-small'}}>
		          		<b>Dropped</b> Student voluntarily opted out of the application process or Student did not complete their application/documentation and has ceased responding to communication requests.
		          	</div>
      				</ListGroup.Item>
		        </ListGroup>          
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  const Documents = (props) => {
    return (
      <ListGroup.Item className="border-o">
        <Row className="ps-lg-1">
          <Col xs="auto">
            <div className={`icon-shape icon-xs icon-documents rounded`}>
              <DocumentTextIcon />
            </div>
          </Col>
          <Col className="ms-n2 mb-3">
            <h5 className="fw-bold mb-1">
              Required Documents Processing
            </h5>
		        <ListGroup className="list-group-flush list-group-timeline">
      				<ListGroup.Item className="border-o">
		          	<div>FAFSA, Transcripts, and Employment Verification</div>
		          	<div className="timeline-detail">
									While gathering mandatory documents is not a distinct phase, it is a key part of the admissions process carried on throughout 
									the Application and Enrollment phases.  Document processing involves submitting requests for documents, 
									following up on requests, then processing and reviewing the returned documents.  
									<br/><br/>All of the requested documents are provided by third party institutions or government bueracracies
									processing their own form and applications, therefore gathering these documents can take from a few days to several 
									weeks to complete.<br/><br/>
		          	</div>
		          	<div className="timeline-detail ms-4" style={{fontSize:'x-small'}}>
		          		<b>FAFSA</b> All students participating in the program must submit a Free Application for Federal Student Aid (FAFSA) and any associated documentation that is requested.
		          	</div>
		          	<div className="timeline-detail ms-4" style={{fontSize:'x-small'}}>
		          		<b>Transcripts</b> Students must request and/or provide transcripts from previous educational institutions that they have attended.
		          	</div>
		          	<div className="timeline-detail ms-4" style={{fontSize:'x-small'}}>
		          		<b>Employment Verification</b> Employment verification of the student from the owner/operator is required by the institution.
		          	</div>
      				</ListGroup.Item>
      				</ListGroup>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  const Enrollment = (props) => {
    return (
      <ListGroup.Item className="border-o  pe-0">
        <Row className="ps-lg-1">
          <Col xs="auto">
            <div className={`icon-shape icon-xs icon-enrolled rounded`}>
              <AcademicCapIcon  />
            </div>
          </Col>
          <Col className="ms-n2 mb-3">
            <h5 className="fw-bold mb-1">
              Enrollment Phase
            </h5>
		        <ListGroup className="list-group-flush list-group-timeline">
      				<ListGroup.Item className="border-o">
		          	<div>Accepted</div>
		          	<div className="timeline-detail">
		          		Student has been successfully complete the Application phase, has been granted admission, and is ready to start the registration process.
		          	</div>		          	
      				</ListGroup.Item>
      				<ListGroup.Item className="border-o">		          	
		          	<div>Registration</div>
		          	<div className="timeline-detail">
		          		Student is in the process of selecting and registering for classes.
		          	</div>		          	
      				</ListGroup.Item>
      				<ListGroup.Item className="border-o">
		          	<div>Confirmed</div>
		          	<div className="timeline-detail">
		          		Student is accepted and registered for the upcoming term.
		          	</div>		          	
      				</ListGroup.Item>
      				<ListGroup.Item className="border-o">
		          	<div>Active</div>
		          	<div className="timeline-detail">
		          		Student is officially enrolled and is currently attending classes <i>(students that are taking a semester off 
		          		but are still in good standing are still classified as Active)</i>.
		          	</div>		          	
      				</ListGroup.Item>
      				<ListGroup.Item className="border-o">
		          	<div>Committed</div>
		          	<div className="timeline-detail">
		          		Student is officially enrolled with a course of study assigned, but has not yet begun attending classes.
		          		Students in this status will have a future semester as their start date.
		          	</div>		          	
      				</ListGroup.Item>
      				<ListGroup.Item className="border-o">
		          	<div>Inactive</div>
		          	<div className="timeline-detail">
		          		Formerly active student that is not currently enrolled in classes and it is unclear if they are going to continue
		          		moving forward with their course of study.
		          	</div>		          	
      				</ListGroup.Item>
      				<ListGroup.Item className="border-o">		          	
		          	<div>Withdrawn</div>
		          	<div className="timeline-detail">
		          		Student is no longer attending the university.
		          	</div>		          	
      				</ListGroup.Item>
		        </ListGroup>          
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  const Graduated = (props) => {
    return (
      <ListGroup.Item className="border-o">
        <Row className="ps-lg-1">
          <Col xs="auto">
            <div className={`icon-shape icon-xs icon-graduated rounded`}>
              <AcademicCapIcon />
            </div>
          </Col>
          <Col className="ms-n2 mb-3">
            <h5 className="fw-bold mb-1">
              Graduated!
            </h5>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
        <ListGroup className="list-group-flush ">
          <Inquiry />
          <Application />
          <Documents />
          <Enrollment />
          <Graduated />
        </ListGroup>
  );
};

export const EVModal  = (props) => { 
	const {showme, studentid, onClose } = props;
	const { 
		adminUser,
		endpoint
	} = useAuth();
	 
	const [student, setStudent] = useState(true);
	const [getData, setGetData] = useState(true);
	const [EVStatus, setEVStatus] = useState(true);
	const noaccess = (adminUser && adminUser.role === 'Partner' ) ? true : false ;

	const onShowHandler = (studentid) => {
			setGetData(false)
		  axios.get(endpoint+"/student/default.asp", {
				  		params: { 
				  			'id':studentid, 
				  		}	
			  })  
			  .then(response => {
			  	const theResult = response.data[0];
			  	console.log(theResult);
			  	setStudent(theResult);
			  	setEVStatus(theResult.EmploymentVerified)
			  })
		  	.catch(error => console.log(error));
	}
	
	function closeMe() {
		onClose();
		setGetData(true);
	}


  const onEVConfirm = e => {
//  	const newEVStatus = e.target.value;
//		setEVStatus(e.target.value);
	  axios.get(endpoint+"/student/verify.asp", {
	  		params: { 
	  			'id':studentid, 
	  			'verified': EVStatus
	  		}	
		  })  
		  .then(response => {
		  	const theResult = response.data;
		  	console.log(theResult);
		  })
	  	.catch(error => console.log(error));    

  }
  
  const onEVChange = e => {
  	const newEVStatus = e.target.value;
		setEVStatus(e.target.value);
  }
  
	const EVHeader  = (props) => {
		const {ev} = props;
		switch (ev) {
			case 'Eligible':
				return <div><CheckIcon color="green" /> Current Employment Status is <b>{ev}</b></div>
			case 'Unverified':
				return <div className='unverified'><ExclamationIcon color="gold" /> Current Employment Status is <b>{ev}</b></div>
			default:
				return <div><XIcon color="red"/> Current Employment Status is <b>{ev}</b></div>
		}

	}
	  
	
	return (
  <Modal centered show={showme} onHide={() => {}} onShow ={showme && getData && onShowHandler(studentid)}>
    <Modal.Header style = {{alignItems: "normal"}}>
      <Modal.Title>
      	<div className="float-start">
	      	<h4 className="mb-0">{student && student.FirstName} {student && student.LastName}</h4>
	      	<h6><i>{student.LocationName}</i></h6>
	      </div>
	      <div className="ms-4 float-end text-right">
					<Button variant="default" href={`sms:${student.Mobile}?&body=Education Program:`} size="sm" target="_blank">
		      	<ChatIcon className="icon icon-xxs" color="rgba(115,164,11,100%)"/>
						<div style={{fontSize: "0.7rem"}}>Message</div>
					</Button>
					<Button variant="default" href={`mailto:${student.Email}?&body=&subject=Education Program`} target="_blank">
		      	<MailIcon className="icon icon-xxs" color="rgba(115,164,11,100%)"/>
						<div style={{fontSize: "0.7rem"}}>Email</div>
					</Button>
	      </div>

      </Modal.Title>
      <Button variant="close" aria-label="Close" onClick={closeMe} />
  	</Modal.Header>
      <Modal.Body>
      <div className="small studentstatus">
      	<h5 className="mb-0 "><b>Phase: </b>{student.StudentSummaryStatus}</h5>
      	<h6><b>Status: </b>{student.StudentStatus} {((student.StatusReason === '') ? '': ' ('+student.StatusReason+')')} on {student.AssignedDate}</h6>
				<hr/>
				<div>
	      	<div className="mb-3 ms-1 ">
	      		<EVHeader ev={EVStatus} />
						<small>Click below to change {student && student.FirstName} {student && student.LastName}&apos;s status</small></div>
      	</div>       	
			
				<Form>
				  <fieldset className="ms-6">
				    <Form.Check
				      checked={EVStatus === 'Eligible'}
				      type="radio"
				      value="Eligible"
				      label={(<>Eligible <i style={{fontSize:'0.7rem'}}>Employee meets program qualifications</i></>)}
				      name="evoptions"
				      id="Eligible"
				      htmlFor="Eligible"
			        onChange={onEVChange}
				      />

				    <Form.Check
				      checked={EVStatus === 'Not Eligible'}
				      type="radio"
				      value="Not Eligible"
				      label={(<>Not Eligible <i style={{fontSize:'0.7rem'}}>Employee does not meet program qualifications</i></>)}
				      name="evoptions"
				      id="NotEligible"
				      htmlFor="NotEligible"
			        onChange={onEVChange}
				      />

				    <Form.Check
				      checked={EVStatus === 'Not Employee'}
				      type="radio"
				      value="Not Employee"
				      label="Not Employee"
				      name="evoptions"
				      id="NotEmployee"
				      htmlFor="NotEmployee"
			        onChange={onEVChange}
				      />

				    <Form.Check
				      checked={EVStatus === 'Terminated'}
				      type="radio"
				      value="Terminated"
				      label="Terminated"
				      name="evoptions"
				      id="Terminated"
				      htmlFor="Terminated"
			        onChange={onEVChange}
				      />

				    <Form.Check
				      checked={EVStatus === 'Unverified'}
				      type="radio"
				      value="Unverified"
				      label={(<>Unverified <i style={{fontSize:'0.7rem'}}>Employment status has not been verified</i></>)}
				      name="evoptions"
				      id="Unverified"
				      htmlFor="Unverified"
			        onChange={onEVChange}
				      />


				  </fieldset>
				</Form>
				<div>
				  <Alert variant="danger" style={{display:(['Not Eligible', 'Not Employee', 'Terminated'].includes(EVStatus)) ? 'block':"none"}}>
				    <div>Selecting the {EVStatus} status will cause this Team Member&apos; to no longer be visible on the Operator Dashboard. 
				    This record will continue be accessible via the Team Members menu item.</div>
				  </Alert>				
				</div>
      		<div>
		      		A Team Members Employment Status must be marked as <b>Eligible</b> and they meet admission requirements to particpate in the Employee Sponsored Higher Education (ESHE) program.  
		      		
      		</div>
      </div>
      
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" size="sm" 
      	onClick={() => {(noaccess) ? alert("Verification Update Not Allowed with Partner Admin access") : onEVConfirm(); closeMe() }}>
        Confirm Status Change
      </Button>
      <Button variant="info" size="sm" onClick={() => {closeMe()}}>
        Cancel
      </Button>
    </Modal.Footer>
  </Modal>  
  )	
};


/* Participant Location Update Modal */
export const LocationModal  = (props) => { 
	const {showme, studentid, onClose } = props;
	const { 
		user,
		adminUser,
		endpoint
	} = useAuth();
	 
	const locations = user.locationList;
	const [student, setStudent] = useState(true);
	const [getData, setGetData] = useState(true);
	const [studentLocation, setStudentLocation] = useState('');
	const [studentLocationID, setStudentLocationID] = useState('');
	const noaccess = (adminUser && adminUser.role === 'Partner' ) ? true : false ;

	const onShowHandler = (studentid) => {
			setGetData(false)
		  axios.get(endpoint+"/student/default.asp", {
				  		params: { 
				  			'id':studentid, 
				  		}	
			  })  
			  .then(response => {
			  	const theResult = response.data[0];
			  	console.log(theResult);
			  	setStudent(theResult);
			  	setStudentLocation(theResult.LocationName)
			  	setStudentLocationID(theResult.LocationID)
			  })
		  	.catch(error => console.log(error));
	}
	
	function closeMe() {
		onClose();
		setGetData(true);
	}


  const onLocationChange = e => {
  	if (noaccess) {
  		alert("Location Update Not Allowed with Partner Admin access");
  		closeMe();
  	} else {
	  	const newLocationID = e.target.value;
			setStudentLocationID(e.target.value);
		  axios.get(endpoint+"/student/setlocation.asp", {
		  		params: { 
		  			'id':studentid, 
		  			'locationid': newLocationID
		  		}	
			  })  
			  .then(response => {
			  	const theResult = response.data;
			  	console.log(theResult);
			  })
		  	.catch(error => console.log(error));    
	  }	  
	}	

  const StoreLocation = (props) => {
    const { locationid, locationname } = props;
		
    return (
				<option value={locationid} selected={studentLocationID === locationid}>{locationname}</option>
    );
  }
	
	return (
  <Modal centered show={showme} onHide={() => {}} onShow ={showme && getData && onShowHandler(studentid)}>
    <Modal.Header style = {{alignItems: "normal"}}>
      <Modal.Title>
      	<div className="float-start">
	      	<h4 className="mb-0">{student && student.FirstName} {student && student.LastName}</h4>
	      	<h6><i>{student.LocationName}</i></h6>
	      </div>
	      <div className="ms-4 float-end text-right">
					<Button variant="default" href={`sms:${student.Mobile}?&body=Education Program:`} size="sm" target="_blank">
		      	<ChatIcon className="icon icon-xxs" color="rgba(115,164,11,100%)"/>
						<div style={{fontSize: "0.7rem"}}>Message</div>
					</Button>
					<Button variant="default" href={`mailto:${student.Email}?&body=&subject=Education Program`} target="_blank">
		      	<MailIcon className="icon icon-xxs" color="rgba(115,164,11,100%)"/>
						<div style={{fontSize: "0.7rem"}}>Email</div>
					</Button>
	      </div>

      </Modal.Title>
      <Button variant="close" aria-label="Close" onClick={closeMe} />
  	</Modal.Header>
      <Modal.Body>
      <div className="small studentstatus">
      	<div className="mb-3 ms-1 ">
					Click below to change {student && student.FirstName} {student && student.LastName}&apos;s location from <i>{studentLocation}</i>
				</div>			
				<Form>
				  <fieldset className="ms-6">
					  <Form.Group className="mb-3">
					    <Form.Label>Update Team Member Location</Form.Label>
							<Form.Select size="sm"  
								className="py-0 pe-4" 
								style={{width:"inherit", fontSize: '1.0rem', borderRadius:'0.25rem', backgroundPosition:'right 0.3rem center'}}
								onChange={onLocationChange}>
				    		{locations.map(st => <StoreLocation key={`location-${st.LocationID}`} {...st} />)}
							</Form.Select>
					  </Form.Group>
				  </fieldset>
				</Form>
      </div>
      
    </Modal.Body>
    <Modal.Footer>
      <Button variant="info" size="sm" onClick={closeMe}>
      	Close
      </Button>
    </Modal.Footer>
  </Modal>  
  )	
};

