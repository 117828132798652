import React, { useRef, useCallback, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Card } from 'react-bootstrap';


export default () => {
  const padRef = useRef(null);
  const [canvas, setCanvas] = useState(undefined);
  const [canvasVisibility, setCanvasVisibility] = useState(false);

  const clearSignatureCanvas = useCallback(() => {
    setCanvas(undefined);
    setCanvasVisibility(false);

  }, []);

  const handleGetCanvas = useCallback(() => {
    const canvas = padRef?.current?.toDataURL();

    setCanvas(canvas);
    setCanvasVisibility(true);
  }, []);

  return (
  <Card style={{ width: '18rem' }}>
      <Card.Body >
        <Card.Title>Signature Box</Card.Title>
			    <div style={{ backgroundColor: "#ddd" }} >
			      <SignatureCanvas
			        ref={padRef}
			        canvasProps={{
			          width: 250,
			          height: 100
			        }}
			      />

			      <hr />

			      {canvasVisibility && <img src={canvas} alt="signature" />}

			      <button onClick={clearSignatureCanvas}>clear</button>
			      <button onClick={handleGetCanvas}>save</button>
			    </div>
      </Card.Body>
    </Card>
  );
}
