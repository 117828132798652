
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from "moment-timezone";
import { useDropzone } from "react-dropzone";
import { CalendarIcon, CreditCardIcon, LockClosedIcon } from "@heroicons/react/solid";
import { Col, Row, Card, Form, Image, Button, InputGroup, Modal } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAuth } from "pages/access/useAuth"; 


export const GeneralInfoForm = () => {
	const [showProfileUpdateConfirm, setShowProfileUpdateConfirm] = useState(false);
	const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
	
	const [hsContactID, setHSContactID] = useState('');
	const [hsCompanyID, setHSCompanyID] = useState('');
	
	const { 
		user,
		endpoint
	} = useAuth();  
	
	const firstName = useRef("");
	const lastName = useRef("");
	const middleName = useRef("");
	const prefFirstName = useRef("");
	const title = useRef("");
	const companyName = useRef("");
	const profileImagePath = useRef("");
	const companyIdentifier = useRef("");
	const email = useRef("");
	const email2 = useRef("");
	const companyEmail = useRef("");
	const companyMobile = useRef("");
	const companyPhone = useRef("");
	const mobilePhone = useRef("");
	const altPhone = useRef("");
	const address1 = useRef("");
	const address2 = useRef("");
	const city = useRef("");
	const state = useRef(""); 
	const postalcode = useRef("");

  const updateProfile = () => {
    var payload = {
			firstname: firstName.current.value,
			lastname: lastName.current.value,
			middlename: middleName.current.value,
			preffirstname: prefFirstName.current.value,
			title: title.current.value,
			companyname:companyName.current.value,
			companyidentifier: companyIdentifier.current.value,
			email: email.current.value,
			email2: email2.current.value,
			companyemail: companyEmail.current.value,
			mobile: mobilePhone.current.value,
			phone: altPhone.current.value,
			companymobile: companyMobile.current.value,
			companyphone: companyPhone.current.value,
			address1: address1.current.value,
			address2: address2.current.value,
			city: city.current.value,
			state: state.current.value,
			postalcode: postalcode.current.value,
			hscontactid: hsContactID,
			hscompanyid: hsCompanyID
/*
			profileimagepath: profileImagePath.current.value
			gender: gender.current.value,
			birthdate: birthday,
*/
    };
		
		let form_data = new URLSearchParams(payload).toString();
		
    axios.post(endpoint+'/company/operator/default.asp', form_data, {
    		params: { 'id': user.uid, 'companyid':user.companyid }	    	
    	})
		.then((response) => {
			updateHS(payload);
			setShowProfileUpdateConfirm(true);
    })
		.catch(error => console.log(error.response.data));			
  };  /* end updateProfile */
  
  function updateHS(payload) {
  	
  	
  };  /* end updateHS */
  
  useEffect(() => {
  	
    axios.get(endpoint+'/company/operator/default.asp', {
			params: { 'id': user.uid }	    	
  	
    })
		  .then(response => {
		  	const result = response.data[0];
		  	
				firstName.current.value = result.firstname;
				lastName.current.value = result.lastname;
				prefFirstName.current.value = result.preffirstname;
				companyEmail.current.value = result.companyemail;
				title.current.value = result.title
				companyName.current.value = result.companyname;
				companyIdentifier.current.value = result.companyidentifier;
				email.current.value = result.email;
				email2.current.value = result.email2;
			 	companyMobile.current.value = result.companymobile;
				companyPhone.current.value = result.companyphone;
				mobilePhone.current.value = result.mobile;
				altPhone.current.value = result.altphone;
				address1.current.value = result.address1;
				address2.current.value = result.address2;
				city.current.value = result.city;
				state.current.value = result.state;
				postalcode.current.value = result.postalcode;
				
				setHSContactID(result.hscontactid);
				setHSCompanyID(result.hscompanyid);
				
// JKG - just stuck profileImagePath in a hidden field for now to suppress the error msg
/*
				middleName.current.value = result.middlename;
				profileImagePath.current.value = result.profileimagepath;
				gender.current.value = result.gender;
				setBirthday(result.birthdate);
*/				
			})
	  	.catch(error => console.log(error));	
	  	
	},);    
  
	const ProfileUpdateConfirm  = (props) => {
		const navigate = useNavigate();
		
		return (
	  <Modal centered show={showProfileUpdateConfirm} onHide={() => navigate('/dashboard/dashboardoperator.js')}>
	    <Modal.Body>
	        <h5>Profile Updated</h5>
	    </Modal.Body>
	    <Modal.Footer>
	      <Button variant="info" size="sm" onClick={() => navigate('/dashboard/dashboardoperator.js')}>
	      	Back to Dashboard
	      </Button>
	    </Modal.Footer>
	  </Modal>  
	  );	
	};

	const ChangePasswordModal = () => {
		return (
	  <Modal centered show={showChangePasswordModal} >
	    <Modal.Header style = {{alignItems: "normal"}}>
      	<Button variant="close" aria-label="Close" onClick={() => setShowChangePasswordModal(false)} />
      </Modal.Header>
	    <Modal.Body>
	        <PasswordForm onClose={() => {setShowChangePasswordModal(false) }}/>
	    </Modal.Body>
	  </Modal>  
	  );	
	}
	
	const changePassword = () => {
		setShowChangePasswordModal(true);
	}
	
  return (
    <Card border="0" className="mb-0">
      <Card.Body>
       	<h4 className="mt-4 mb-2">Personal Information </h4>
        <Form id="myform">
					<Form.Control required type="hidden" placeholder="image path" ref={profileImagePath}/>
          <Row>
            <Col md={4} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter first name" ref={firstName}/>
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter  last name" ref={lastName}/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <Form.Group id="prefFirstName">
                <Form.Label>Preferred First Name</Form.Label>
                <Form.Control required type="text" placeholder="Preferred First Name" ref={prefFirstName}/>
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="title">
                <Form.Label>Title</Form.Label>
                <Form.Control required type="text" placeholder="Title" ref={title}/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="email">
                <Form.Label>Primary Email <i style={{fontSize: "0.7rem"}}>(this address is your userid to sign in)</i></Form.Label>
                <Form.Control required type="email" placeholder="emailaddress@company.com"  ref={email}/>
              </Form.Group>
            </Col>
            <Col md={8} className="mb-3">
              <Form.Group id="email2">
                <Form.Label>Alternate Email</Form.Label>
                <Form.Control required type="text" placeholder="personal email" ref={email2}/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="mobilePhone">
                <Form.Label>Mobile Phone</Form.Label>
                <Form.Control required type="text" placeholder="+12-345 678 910"  ref={mobilePhone}/>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="altPhone">
                <Form.Label>Alternate Phone</Form.Label>
                <Form.Control required type="text" placeholder="+12-345 678 910"  ref={altPhone}/>
              </Form.Group>
            </Col>
          </Row>
        <hr className="my-4"></hr>
          <Row>
            <Col md={6} className="mb-3">
							<h6 className="d-inline">Password: </h6><Button variant="info" className="ms-2 btn-sm" onClick={() => changePassword()}>Change Password</Button>
            </Col>
          </Row>
        <hr className="my-4"></hr>
        <h4 className="mb-2">Corporate Information</h4>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="companyName">
                <Form.Label>Company Name</Form.Label>
                <Form.Control required type="text" placeholder="Company Name" ref={companyName}/>
              </Form.Group>
            </Col>
            <Col md={3} className="mb-3">
              <Form.Group id="companyIdentifier">
                <Form.Label>Operator #</Form.Label>
                <Form.Control required type="text" placeholder="Operator #" ref={companyIdentifier}/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="companyPhone">
                <Form.Label>Company Phone</Form.Label>
                <Form.Control type="text" placeholder="+12-345 678 910"  ref={companyPhone}/>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="companyMobile">
                <Form.Label>Company Mobile Phone</Form.Label>
                <Form.Control type="text" placeholder="+12-345 678 910"  ref={companyMobile}/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="companyEmail">
                <Form.Label>Company Email</Form.Label>
                <Form.Control required type="email" placeholder="companyemail@company.com"  ref={companyEmail}/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={9} className="mb-3">
              <Form.Group id="address1">
                <Form.Label>Company Address</Form.Label>
                <Form.Control required type="text" placeholder="Corporate address line 1"  ref={address1}/>
              </Form.Group>
            </Col>
            <Col sm={9} className="mb-3">
              <Form.Group id="address2">
                <Form.Label></Form.Label>
                <Form.Control required type="text" placeholder="Corporate address line 2"  ref={address2}/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={4} className="mb-3">
              <Form.Group id="city">
                <Form.Label>City</Form.Label>
                <Form.Control required type="text" placeholder="City"  ref={city}/>
              </Form.Group>
            </Col>
            <Col sm={4} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Select state</Form.Label>
                <Form.Select id="state" defaultValue="0"  ref={state}>
                  <option value="0">State</option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group id="postalcode">
                <Form.Label>Zip</Form.Label>
                <Form.Control required type="text" placeholder="ZIP"  ref={postalcode}/>
              </Form.Group>
            </Col>
          </Row>

{/*
          <Row className="align-items-center">
            <Col md={4} className="mb-3">
              <Form.Group id="birthday">
                <Form.Label>Birthday</Form.Label>
                <Datetime
                  timeFormat={false}
                  initialValue={moment(birthday)}
                  onChange={(thedate) => {(setBirthday(thedate ? moment(thedate).format("MM/DD/YYYY") : "")); } }
                  onOpen={(props) => {
                  	let thevalue = (birthday ? moment(birthday).format("MM/DD/YYYY") : "") ;
                  } }
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <InputGroup.Text>
                        <CalendarIcon className="icon icon-xs" />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        value={birthday ? moment(birthday).format("MM/DD/YYYY") : ""}
                        placeholder="mm/dd/yyyy"
                        onFocus={openCalendar} 
                        onChange={() => { }} />
                    </InputGroup>
                  )} />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="gender">
                <Form.Label>Gender</Form.Label>
                <Form.Select defaultValue="0" className="mb-0"  ref={gender}>
                  <option value="Gender">Gender</option>
                  <option value="Female">Female</option>
                  <option value="Male">Male</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
*/}
          <Row className="justify-content-md-center">
            <Col md={6} className="d-grid mb-3">
	        		<Button variant="info" type="button" onClick={updateProfile} className="mt-2 animate-up-2">
	        			Update Profile
	        		</Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    	<ProfileUpdateConfirm />
    	<ChangePasswordModal />
    </Card>
  );
};


export const DropFilesForm = () => {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: files => setFiles(files.map(file => ({
      ...file,
      preview: URL.createObjectURL(file)
    })))
  });

  const DropzoneFile = (props) => {
    const { path, preview } = props;

    return (
      <Col xs={6} className="dropzone-preview">
        <Image src={preview} className="dropzone-image" />
        <Card.Text className="dropzone-filename">
          {path}
        </Card.Text>
      </Col>
    );
  };

  return (
    <>
      <Form {...getRootProps({ className: "dropzone rounded d-flex align-items-center justify-content-center mb-4" })}>
        <Form.Control {...getInputProps()} />
        <div className="dz-default dz-message text-center">
          <p className="dz-button mb-0">Drop files here to upload</p>
        </div>
      </Form>
      <Row className="dropzone-files">
        {files.map(file => <DropzoneFile key={file.path} {...file} />)}
      </Row>
    </>
  );
};

export const CardDetailsForm = () => {
  const RequiredLabel = ({ label }) => (
    <Form.Label>
      {label}
      <span className="text-danger"> * </span>
    </Form.Label>
  );

  return (
    <Card as="form" border="0" className="shadow p-3 pb-4 mb-4">
      <Card.Header className="mx-lg-4 p-0 py-3 py-lg-4 mb-4 mb-md-0">
        <h5 className="mb-0">Card details</h5>
      </Card.Header>
      <Card.Body className="p-0 p-md-4">
        <Row className="justify-content-center">
          <Col xs={12}>
            <Form.Group id="cardNameLabel">
              <RequiredLabel
                label={<span className="small text-dark">(Full name as displayed on card)</span>}
              />
              <InputGroup className="mb-4">
                <Form.Control required type="text" placeholder="Name on Card" />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} className="mb-4">
            <Form.Group id="cardNumberLabel">
              <RequiredLabel label="Card Number" />
              <InputGroup>
                <InputGroup.Text className="text-gray-600">
                  <CreditCardIcon className="icon icon-xs" />
                </InputGroup.Text>
                <Form.Control required type="number" placeholder="0000 0000 0000 0000" />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} md={6} className="mb-4 mb-lg-0">
            <Form.Group id="cardCVCLabel">
              <RequiredLabel label="CVC" />
              <Form.Control required type="number" placeholder="CVC" />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group id="cardExpiryLabel">
              <RequiredLabel label="Card Expiry" />
              <InputGroup>
                <InputGroup.Text className="text-gray-600">
                  <CalendarIcon className="icon icon-xs" />
                </InputGroup.Text>
                <Form.Control required type="number" placeholder="MM / YY" />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} className="mt-4">
            <Button variant="gray-800" className="animate-up-2 mt-2" type="submit">
              Update
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};


export const PasswordForm = (props) => {
	const { onClose } = props;
	const { 
		secToken,
		user,
		endpoint
	} = useAuth();

	const [searchParams] = useSearchParams(); 
	const reset_guid = searchParams.get("reset_guid");
	const userid = searchParams.get("userid");
	
	const email = (reset_guid && userid) ? userid : (user && user.email);
	
	const refPassword = React.createRef(); 		 
	const refConfirm = React.createRef(); 		
	const refEmail = React.createRef(); 		
	const navigate = useNavigate();
	
	
  function handleConfirm(event) {
		if (refPassword.current.value !== refConfirm.current.value) {
			alert("Password and Confirm fields must match.  Please try again.");
			refConfirm.current.value = '';
		}
  }

	const handleSubmit = (event) => {			
	  event.preventDefault();


    try {
    	if (user && user.email) {
		    var payload = {
					password: refPassword.current.value,
					pswd_reset_date: moment().format("MM/DD/YYYY")
				}				
				let form_data = new URLSearchParams(payload).toString();
		    axios.post(endpoint+"/users/signin/changepassword.asp", form_data, {
		  		params: { 'id': user.uid }	
	  		})
				.then((response) => {
		    	if (response.data === 'success') {
						alert('Your Password was changed');
			    	(onClose) ? onClose() : navigate('/', {replace: true});
			    } else {
			    	alert('There was a problem updating your password.  Please try again');
					refPassword.current.value = '';
					refConfirm.current.value = '';
			    	
			    }
		    })
				.catch(error => console.log(error.response.data));	
			} else {
		    var payloadreset = {
					new_pswd: refPassword.current.value,
					confirm_pswd: refConfirm.current.value,
					reset_guid: reset_guid,
					userid: refEmail.current.value
				}				
				let form_data = new URLSearchParams(payloadreset).toString();
		    axios.post(endpoint+"/users/signin/ajax-lost-pswd-reset.asp", form_data, {
					params: { 
						'sectoken': secToken, 
		  		}
		  	})
				.then((response) => {
		    	if (response.data === '') {
						alert('Password Changed');
			    	navigate('/', {replace: true});
			    } else {
			    	alert('There was a problem updating your password.  Please try again');
					refPassword.current.value = '';
					refConfirm.current.value = '';
			    	
			    }
		    })
				.catch((error) => {
					console.log(error.response.data)
				
				});	
				
			}
    } catch {
			return('axios failed')
		}

	}

	
		return(
		<>
      <h3 className="mb-4">Change Password</h3>
      <Form onSubmit={handleSubmit}>
        <Form.Group id="email" className="mb-4">
        <Form.Label>Your Email</Form.Label>
          <InputGroup>
            <Form.Control disabled readOnly type="email" placeholder="Email Not Found" ref={refEmail} value={email}/>
          </InputGroup>
        </Form.Group>
        <Form.Group id="password" className="mb-4">
          <Form.Label>New Password</Form.Label>
          <InputGroup>
            <InputGroup.Text>
              <LockClosedIcon className="icon icon-xs text-gray-600" />
            </InputGroup.Text>
            <Form.Control required type="password" placeholder="Password" ref={refPassword}/>
          </InputGroup>
        </Form.Group>
        <Form.Group id="confirmPassword" className="mb-4">
          <Form.Label>Confirm New Password</Form.Label>
          <InputGroup>
            <InputGroup.Text>
              <LockClosedIcon className="icon icon-xs text-gray-600" />
            </InputGroup.Text>
            <Form.Control required type="password" placeholder="Confirm Password" ref={refConfirm} onBlur={handleConfirm} />
          </InputGroup>
        </Form.Group>
        <div className="d-grid">
          <Button variant="primary" type="submit">
            Submit Password Change
          </Button>
        </div>
      </Form>
     </>
		)
	}